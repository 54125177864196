import React, { setGlobal } from 'reactn'
import { Link } from 'react-router-dom'
import QrReader from 'react-qr-reader'
import {
    isAndroid,
    isIOS,
} from "react-device-detect"
import "../assets/css/style.css"

export default class Login extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loaded: false,
            mode: 'qr',
            code: '',
            codeError: false,
            qrError: false,
            qr: false,
            scanStatus: ''
        }
        this.handleLoad = this.handleLoad.bind(this);
    }

    componentDidMount() {
        //localStorage.removeItem('ga_auth_key');
        window.addEventListener('load', this.handleLoad);
        if(isAndroid){
            document.body.classList.add('android-mode');
        }
    }

    componentWillUnmount() {
        window.removeEventListener('load', this.handleLoad)
    }

    handleLoad() {
        this.setState({
            loaded: true
        });
    }

    setLoginMode = (mode) =>{
        this.setState({
            mode: mode,
            code: '',
            codeError: false
        });
    }

    handleScan = data => {

        this.setState({
            scanStatus: data
        });

        if (data) {
            localStorage.removeItem('start');
            localStorage.removeItem('access');
            localStorage.removeItem('room');
            localStorage.removeItem('timezone');
            localStorage.removeItem('text_amenities');
            localStorage.removeItem('info_img');
            localStorage.removeItem('descr_amenities');
            localStorage.removeItem('amenities');
            localStorage.removeItem('pwa');
            window.location.href = data;
        }

    }
    handleError = err => {
        this.setState({
            mode: 'qr',
            code: '',
            codeError: false,
            qrError: false,
            qr: false,
            scanStatus: ''
        })
    }

    openQrScanner = () => {
        this.setState({
            qr: true
        })
    }

    renderQrPerPlatform = () => {
        if(isAndroid){
            return(
                <QrReader
                    delay={100}
                    onError={this.handleError}
                    onScan={this.handleScan}
                    facingMode={'environment'}
                    style={{ width: '100%', height: '100%' }}
                />
            )
        }else{
            return(
                <QrReader
                    delay={100}
                    onError={this.handleError}
                    onScan={this.handleScan}
                    style={{ width: '100%', height: '100%' }}
                />
            )
        }
    }

    renderQrScanner = () =>{
        if(this.state.qr === true){
            return(
                <div className="qr__wrap">
                    <div className="angle angle__1" />
                    <div className="angle angle__2" />
                    <div className="angle angle__3" />
                    <div className="angle angle__4" />
                    <div className="qr__area">
                        {this.renderQrPerPlatform()}
                    </div>
                </div>
            )
        }
    }

    renderQrActions = () => {
        if(this.state.qr === true){
            return(
                <div className="row row__custom">
                    <div className="col-12 col__custom">
                        <Link to={"/info"} className="btn__custom btn__custom-default d-flex align-items-center justify-content-center show__start">
                            Back
                        </Link>
                    </div>
                </div>
            )
        }else{
            return(
                <div className="row row__custom">
                    <div className="col-6 col__custom">
                        <Link to={"/info"} className="btn__custom btn__custom-default d-flex align-items-center justify-content-center show__start">
                            Back
                        </Link>
                    </div>
                    <div className="col-6 col__custom">
                        <div onClick={()=>{this.openQrScanner()}} className="btn__custom d-flex align-items-center justify-content-center">
                            Scan
                        </div>
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <div className={'page page__login animated fadeIn loaded'}>
                <div className="decor decor__1" />
                <div className="decor decor__2" />
                <div className="decor decor__3" />
                <div className="container d-flex align-items-end">
                    <div className="box__list">
                        <div className={'box box__start ' + ((this.state.mode === 'start') ? 'active' : '')}>
                            <div className="box__header">
                                <div className="box__title">
                                    Welcome to<br />
                                    Katerina Hotel Orlando
                                </div>
                            </div>
                            <div className="box__actions">
                                <div className="row row__custom">
                                    <div className="col-6 col__custom">
                                        <div onClick={()=>{this.setLoginMode('code')}} className="btn__custom d-flex align-items-center justify-content-center show__code">
                                            Reservation code
                                        </div>
                                    </div>
                                    <div className="col-6 col__custom">
                                        <div onClick={()=>{this.setLoginMode('qr')}} className="btn__custom d-flex align-items-center justify-content-center show__qr">
                                            QR code
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'box box__qr ' + ((this.state.mode === 'qr') ? 'active' : '')}>
                            <div className="box__header">
                                <div className="box__title">
                                    In order to proceed with your request, please identify yourself by scanning the QR code in your room
                                </div>
                            </div>
                            {
                                this.renderQrScanner()
                            }
                            <div className="box__actions">
                                {
                                    this.renderQrActions()
                                }
                            </div>
                        </div>
                        <div className={'box box__code ' + ((this.state.mode === 'code') ? 'active' : '')}>
                            <div className="box__header">
                                <div className="box__title">
                                    Login by reservation code
                                </div>
                            </div>
                            <div className="box__form">
                                <div className={'box__form-label ' + ((this.state.codeError === true) ? 'error' : '')}>
                                    Enter your reservation code
                                </div>
                                <div className={'form__code ' + ((this.state.codeError === true) ? 'error' : '')}>
                                    <div className="row m-0">
                                        <div className="col-2 p-0 form__code-col">
                                            <div className="form__code-input">
                                                <input type="text" placeholder="_" maxLength={1} />
                                            </div>
                                        </div>
                                        <div className="col-2 p-0 form__code-col">
                                            <div className="form__code-input">
                                                <input type="text" placeholder="_" maxLength={1} />
                                            </div>
                                        </div>
                                        <div className="col-2 p-0 form__code-col">
                                            <div className="form__code-input">
                                                <input type="text" placeholder="_" maxLength={1} />
                                            </div>
                                        </div>
                                        <div className="col-2 p-0 form__code-col">
                                            <div className="form__code-input">
                                                <input type="text" placeholder="_" maxLength={1} />
                                            </div>
                                        </div>
                                        <div className="col-2 p-0 form__code-col">
                                            <div className="form__code-input">
                                                <input type="text" placeholder="_" maxLength={1} />
                                            </div>
                                        </div>
                                        <div className="col-2 p-0 form__code-col">
                                            <div className="form__code-input">
                                                <input type="text" placeholder="_" maxLength={1} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box__actions">
                                <div className="row row__custom">
                                    <div className="col-6 col__custom">
                                        <div onClick={()=>{this.setLoginMode('start')}} className="btn__custom btn__custom-default d-flex align-items-center justify-content-center show__start">
                                            Back
                                        </div>
                                    </div>
                                    <div className="col-6 col__custom">
                                        <div onClick={()=>{this.sendReservatonCode()}} className="btn__custom d-flex align-items-center justify-content-center">
                                            Next
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}