import React, { setGlobal } from 'reactn'
import { Link, Redirect } from 'react-router-dom'
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import TextareaAutosize from 'react-textarea-autosize';
import 'react-html5-camera-photo/build/css/index.css';

import Footer from './Footer'
import Notify from './Notify'
import "../assets/css/animate.css"
import "../assets/css/bootstrap.min.css"
import "../assets/css/style.css"
import {
    isMobile,
    isIOS,
    isMobileSafari,
    isAndroid,
    isChrome
} from "react-device-detect";
import Linking from "./Linking";
const API_URL_BEFORE = 'https://';
const API_URL_2 = '.hkeeper.';
const API_URL_3 = '/hk/ga_api';

let chat_data = {};

export default class Chat extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            act_info: null,
            page_title_default: 'Chat with us',
            page_title: 'Chat with us',
            currentAmen: {},
            loaded: false,
            messages: [],
            messGroups: [],
            messError: false,
            messText: '',
            domain: '',
            access: 'limited',
            chat_data: {},
            part: '',
            camera: false,
            att_photo_uri: '',
            img_modal: false,
            img_modal_file: '',
            photo_preview: false,
            ch_f_h: 60,
            no__photo: false
        }
        this.handleLoad = this.handleLoad.bind(this);
        this.updateChat = this.updateChat.bind(this);
    }

    componentDidMount() {
        this.setGlobal({
            page: 'chat',
            access: 'full'
        });
        this.setGlobal({
           chatnot: false
        });
        document.getElementById('root').setAttribute('style','height:' + window.innerHeight + 'px');
        document.getElementById('chat-box').setAttribute('style','height:' + (window.innerHeight - 108) + 'px');

        if(localStorage.getItem('part')){
            this.setState({
                part: localStorage.getItem('part')
            })
        }

        if(localStorage.getItem('domain')){
            this.setState({
                domain: localStorage.getItem('domain')
            }, ()=>{
                this.checkToken('get');
            })
        }
        if(localStorage.getItem('access')){
            this.setState({
                access: localStorage.getItem('access')
            });
            this.setGlobal({
                access: localStorage.getItem('access')
            });
        }

        window.addEventListener('load', this.handleLoad);
        document.body.classList.add('overflow');
        this.convertMessages();
    }

    componentWillUnmount() {
        window.removeEventListener('load', this.handleLoad);
        document.body.classList.remove('overflow');
    }

    handleLoad() {
        this.setState({
            loaded: true
        });
    }

    checkToken = async(action) => {
        await fetch(API_URL_BEFORE + this.state.domain + API_URL_2 + this.state.part + API_URL_3 + '/token', {
            method: "POST",
            body: JSON.stringify({
                token: localStorage.getItem('ga_auth_key')
            }),
        }).then((response) => response.json()).then((response) => {
            if(response.valid === 0){
                localStorage.setItem('access', 'limited');
                this.setGlobal({
                    access: 'limited'
                });
                this.setState({
                    access: 'limited'
                });
            }

            if(action === 'send'){
                this.sendChatMess();
            }else{
                this.getChat();
            }

            if(response.token !== undefined){
                if(response.token === 'refresh'){
                    let room  = localStorage.getItem('room');
                    let domain  = localStorage.getItem('domain');
                    window.location.href = 'https://concierge.hkeeper.global?r=' + room + '&d=' + domain + '&p=global';
                }
            }else{
                if(isMobileSafari && isMobile && isIOS){
                    this.saveToken(localStorage.getItem('ga_auth_key'), this.state.access, this.state.domain, this.state.part, localStorage.getItem('room'));
                }
            }
        });
    }

    saveToken = async (token, access, domain, part, room) => {
        document.querySelector('#my-manifest-placeholder').setAttribute('href', '/manifest/' + token + '.' + domain + '.' + part + '.' + access+ '.' + room + '.json');
    };

    convertMessages = () => {
        let mess__list = [];
        chat_data = this.state.chat_data;
        Object.keys(chat_data).map((item, i) => {
            let time = new Date(parseInt(item) * 1000);
            let hours = "0" + time.getHours();
            let minutes = "0" + time.getMinutes();
            let mess__item = {
                date: item,
                text: chat_data[item].text,
                author: chat_data[item].author,
                uid: chat_data[item].uid,
                timeFormat: hours.substr(-2) + ':' + minutes.substr(-2),
                type: chat_data[item].type,
                file: chat_data[item].file
            }
            mess__list.push(mess__item);
        })


        const groups = mess__list.reduce((groups, mess) => {
            let date = new Date(parseInt(mess.date) * 1000);

            date = ("0" + date.getDate()).substr(-2) + ' ' + this.getMonth(parseInt(date.getMonth()));
            if (!groups[date]) {
                groups[date] = [];
            }
            groups[date].push(mess);
            return groups;
        }, {});

        this.setState({
            messGroups: groups
        });

        const groupArrays = Object.keys(groups).map((date) => {
            return {
                date,
                mess: groups[date]
            };
        });

        this.setState({
            messages: groupArrays
        }, ()=>{
            this.scrollToBottom()
        });
    }

    getMonth = (month) => {
        let text_month = 'Jan';
        switch(month){
            case 0:
                text_month = 'Jan';
                break;
            case 1:
                text_month = 'Feb';
                break;
            case 2:
                text_month = 'Mar';
                break;
            case 3:
                text_month = 'Apr';
                break;
            case 4:
                text_month = 'May';
                break;
            case 5:
                text_month = 'Jun';
                break;
            case 6:
                text_month = 'Jul';
                break;
            case 7:
                text_month = 'Aug';
                break;
            case 8:
                text_month = 'Sep';
                break;
            case 9:
                text_month = 'Oct';
                break;
            case 10:
                text_month = 'Nov';
                break;
            case 11:
                text_month = 'Dec';
                break;
        }
        return text_month;
    }

    updateChat = () => {
        this.getChat()
    }

    getChat = async () => {
        await fetch(API_URL_BEFORE + this.state.domain + API_URL_2 + this.state.part + API_URL_3 + '/chat', {
            method: "POST",
            body: JSON.stringify({
                uuid: localStorage.getItem('ga_auth_key'),
                action: 'list'
            }),
        }).then((response) => response.json()).then((response) => {
            this.setState({
                chat_data: response
            }, ()=>{
                this.convertMessages();
                this.scrollToBottom();
            });
        });
    }

    showImgFull = (file) => {
        this.setState({
            img_modal_file: file,
            img_modal: true
        })
    }

    renderChatImg = (file) => {
        if(file !== '' && file !== undefined){
            return(
                <div onClick={()=>{
                    this.showImgFull(file)
                }} className="chat__img">
                    <img src={file}/>
                </div>
            )
        }
    }

    contentClickHandler = (e) => {
        const targetLink = e.target.closest('a');
        if(!targetLink) return;
        e.preventDefault();
        this.linking.redirectShow(targetLink.href);
    };

    renderMessages = () => {
        if(this.state.messages.length > 0) {
            return (
                this.state.messages.map((group, i) => (
                    <div key={i} className="chat__group">
                        <div className="chat__date">{group.date}</div>
                        {
                            group.mess.map((item, j) => (
                                <div key={j} className="chat__item-box d-flex">
                                    <div className={'chat__item d-flex ' + ((parseInt(item.uid) === 0) ? 'chat__item-my' : '')}>
                                        <div className="chat__item-content">
                                            <div className="chat__item-heder d-flex justify-content-between">
                                                <div className="chat__item-name">{((parseInt(item.uid) === 0) ? 'You' : item.author)}</div>
                                                <div className="chat__item-time">{((parseInt(i) === 0 && parseInt(j) !== 0) || parseInt(i) !== 0) ? item.timeFormat : ''}</div>
                                            </div>
                                            <div className="chat__text">
                                                <div onClick={this.contentClickHandler} className="text" dangerouslySetInnerHTML={{__html: item.text.replace(/(?:\r\n|\r|\n)/g, '<br/>')}}/>
                                            </div>
                                            {
                                                this.renderChatImg(item.file)
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    )
                )
            )
        }
    }

    sendChat = async (message, uri) => {
        this.setState({
            att_photo_uri: ''
        });
        await fetch(API_URL_BEFORE + this.state.domain + API_URL_2 + this.state.part + API_URL_3 + '/chat', {
            method: "POST",
            body: JSON.stringify({
                uuid: localStorage.getItem('ga_auth_key'),
                message: message,
                file: uri,
                action: 'update'
            }),
        }).then((response) => response.json()).then((response) => {
            this.setState({
                chat_data: response
            }, ()=>{
                this.convertMessages();
            });
        });
    }

    sendChatMess = () => {
        if(this.state.messText === '' && this.state.att_photo_uri === ''){
            this.setState({
                messError: true
            });
        }else{
            this.setState({
                messError: false
            });

            let mess__list = this.state.messGroups;

            let time = new Date(+ new Date() / 1000);
            let hours = "0" + time.getHours();
            let minutes = "0" + time.getMinutes();

            let mess_new_date = Math.round(+ new Date() / 1000);

            let mess__new = {
                date: mess_new_date,
                text: this.state.messText,
                author: "",
                uid: 0,
                timeFormat: hours.substr(-2) + ':' + minutes.substr(-2),
                type: 1,
                file: this.state.att_photo_uri
            };

            this.sendChat(this.state.messText, this.state.att_photo_uri);

            let date = new Date(+ new Date());
            date = ("0" + date.getDate()).substr(-2) + ' ' + this.getMonth(parseInt(date.getMonth()));

            if (!mess__list[date]) {
                mess__list[date] = [];
            }

            mess__list[date].push(mess__new);
            this.setState({
                messGroups: mess__list
            }, ()=>{
                let groupArrays = Object.keys(mess__list).map((date) => {
                    return {
                        date,
                        mess: mess__list[date]
                    };
                });

                this.setState({
                    messages: groupArrays
                }, ()=>{
                    this.scrollToBottom()
                });
                this.setState({
                    messText: ''
                });
            });
        }
    }

    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }

    handleTakePhoto = (dataUri) => {
        this.setState({
            camera: false,
            att_photo_uri: dataUri,
            photo_preview: true
        });
    }

    approveSendPhoto = () => {
        this.setState({
            camera: false,
            photo_preview: false
        }, ()=> {
            this.sendChatMess();
        });
    }

    handleCameraError = (error) => {
        this.setState({
            camera: false,
            photo_preview: false,
            no__photo: true
        });
    }

    renderCamera = () => {
        if(this.state.camera){
            return(
                <div className="camera__box">
                    <Camera
                        onTakePhoto = { (dataUri) => { this.handleTakePhoto(dataUri); } }
                        onCameraError = { (error) => { this.handleCameraError(error); } }
                        idealFacingMode = {FACING_MODES.ENVIRONMENT}
                        idealResolution = {{width: 414, height: 736}}
                        imageType = {IMAGE_TYPES.JPG}
                        imageCompression = {0.97}
                        isMaxResolution = {true}
                        isImageMirror = {false}
                        isSilentMode = {false}
                        isDisplayStartCameraError = {false}
                        isFullscreen = {false}
                        sizeFactor = {1}
                    />
                </div>
            )
        }
    }

    renderCameraAction = () => {
        if(this.state.no__photo !== true){
            return(
                <div onClick={()=>{
                    this.setState({
                        camera: true
                    });
                }} className="chat__action chat__action-right">
                    <i className="la la-camera" />
                </div>
            )
        }
    }

    closePreviewBox = () => {
        this.setState({
            photo_preview: false,
            att_photo_uri: '',
            camera: true
        })
    }

    renderPhotoPreview = () => {
        if(this.state.photo_preview === true){
            return(
                <div className="photo__preview-box fadeIn animated">
                    <img src={this.state.att_photo_uri}/>
                    <div className="photo__preview-actions">
                        <div className="row">
                            <div className="col-6">
                                <div onClick={()=>{
                                    this.closePreviewBox()
                                }} className="photo__preview-actions-btn d-flex align-items-center justify-content-center">
                                    <i className="las la-times" />
                                </div>
                            </div>
                            <div className="col-6">
                                <div onClick={()=>{
                                    this.approveSendPhoto()
                                }}  className="photo__preview-actions-btn d-flex align-items-center justify-content-center">
                                    <i className="las la-check" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    closeImgModal = () => {
        this.setState({
            img_modal: false
        })
    }

    renderImgModal = () => {
        if(this.state.img_modal){
            return(
                <div className="img__modal fadeIn animated d-flex align-items-center justify-content-center">
                    <div onClick={()=>{
                        this.closeImgModal()
                    }} className="close__modal">
                        <svg x="0px" y="0px" viewBox="0 0 512.001 512.001">
                            <path fill="#fff" d="M284.286,256.002L506.143,34.144c7.811-7.811,7.811-20.475,0-28.285c-7.811-7.81-20.475-7.811-28.285,0L256,227.717
                                    L34.143,5.859c-7.811-7.811-20.475-7.811-28.285,0c-7.81,7.811-7.811,20.475,0,28.285l221.857,221.857L5.858,477.859
                                    c-7.811,7.811-7.811,20.475,0,28.285c3.905,3.905,9.024,5.857,14.143,5.857c5.119,0,10.237-1.952,14.143-5.857L256,284.287
                                    l221.857,221.857c3.905,3.905,9.024,5.857,14.143,5.857s10.237-1.952,14.143-5.857c7.811-7.811,7.811-20.475,0-28.285
                                    L284.286,256.002z"/>
                        </svg>
                    </div>
                    <div className="img__modal-content">
                        <img src={this.state.img_modal_file}/>
                    </div>
                </div>
            )
        }
    }

    renderBackLink = () => {
        if(this.state.photo_preview === true || this.state.camera === true){
            return(
                <div onClick={()=>{
                    this.setState({
                        photo_preview: false,
                        camera: false
                    })
                }} className="link__back">
                    <i className="las la-angle-left"/>
                </div>
            )
        }
    }

    render() {
        return (
            <div className={'page page__content animated fadeIn ' + ((this.state.loaded === true) ? 'loaded' : '')}>
                <div className="header d-flex align-items-center justify-content-center">
                    {
                        this.renderBackLink()
                    }
                    <div className="page__title">
                        {this.state.page_title}
                    </div>
                </div>
                <div className="content">
                    <div className="container">
                        <div className="section section__chat">
                            <div id="chat-box" className="chat__box" style={{paddingBottom: this.state.ch_f_h + 'px'}}>
                                <div className="chat__list">
                                    <div className="chat__list-wrap">
                                        {this.renderMessages()}
                                    </div>
                                    <div ref={(el) => { this.messagesEnd = el; }} className="mess__end"></div>
                                </div>
                                <div className={'chat__form  d-flex justify-content-between align-items-center ' + ((this.state.messText !== '') ? 'send-mode' : '')} style={{height: this.state.ch_f_h + 'px'}}>
                                    <label className="chat__field d-flex justify-content-between align-items-center">
                                        <TextareaAutosize onHeightChange={(th) => {
                                            this.setState({
                                                ch_f_h: th + 31
                                            })
                                        }} onChange={(messText)=>{
                                            this.setState({messText: messText.target.value});
                                        }} className={(this.state.messError === true) ? 'error' : ''} type="text" value={this.state.messText} placeholder="Enter your message" />
                                    </label>
                                    {
                                        this.renderCameraAction()
                                    }
                                    <div onClick={()=>{
                                        this.checkToken('send');
                                    }} className="chat__action chat__action-send">
                                        <i className="las la-paper-plane" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.renderImgModal()
                }
                {
                    this.renderCamera()
                }
                {
                    this.renderPhotoPreview()
                }
                <Footer updateChat={this.updateChat} chatnot={this.state.chatnot} reqnot={this.state.reqnot} access={this.state.access} page={'chat'}/>
                <Linking ref={instance => { this.linking = instance; }} />
            </div>
        )
    }
}
