import React, { setGlobal } from 'reactn'
import { Link, Redirect } from 'react-router-dom'
import Footer from './Footer'
import "../assets/css/animate.css"
import "../assets/css/bootstrap.min.css"
import "../assets/css/style.css"
import {
    isMobile,
    isIOS,
    isMobileSafari,
    isAndroid,
    isChrome
} from "react-device-detect";
const API_URL_BEFORE = 'https://';
const API_URL_2 = '.hkeeper.';
const API_URL_3 = '/hk/ga_api';

export default class Requests extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            act_info: null,
            page_title_default: 'My requests',
            page_title: 'My requests',
            mode: 'category',
            currentAmen: {},
            loaded: false,
            requests: [],
            modal_rate: false,
            domain: '',
            access: 'limited',
            requestId: null,
            comment: '',
            disabled: false,
            rate: 0
        }
        this.handleLoad = this.handleLoad.bind(this);
        this.updateRequests = this.updateRequests.bind(this);
    }

    componentDidMount() {
        document.getElementById('root').setAttribute('style','height:' + window.innerHeight + 'px');
        this.setGlobal({
            page: 'requests',
            access: 'full'
        });
        this.setGlobal({
            reqnot: false
        });
        if(localStorage.getItem('access')){
            this.setState({
                access: localStorage.getItem('access')
            });
            this.setGlobal({
                access: localStorage.getItem('access')
            });
        }

        if(localStorage.getItem('part')){
            this.setState({
                part: localStorage.getItem('part')
            });
        }
        if(localStorage.getItem('domain')){
            this.setState({
                domain: localStorage.getItem('domain')
            }, ()=>{
                this.checkToken();
            })
        }
        window.addEventListener('load', this.handleLoad);
    }

    componentWillUnmount() {
        window.removeEventListener('load', this.handleLoad)
    }

    handleLoad() {
        this.setState({
            loaded: true
        });
    }

    saveToken = async (token, access, domain, part, room) => {
        document.querySelector('#my-manifest-placeholder').setAttribute('href', '/manifest/' + token + '.' + domain + '.' + part + '.' + access+ '.' + room + '.json');
    };

    updateRequests = () => {
        this.getRequests()
    }

    getRequests = async () => {
        await fetch(API_URL_BEFORE + this.state.domain + API_URL_2 + this.state.part + API_URL_3 + '/requests', {
            method: "POST",
            body: JSON.stringify({
                uuid: localStorage.getItem('ga_auth_key'),
                action: 'list'
            }),
        }).then((response) => response.json()).then((response) => {
            this.setState({
                requests: response
            });
        });
    }

    checkToken = async() => {
        await fetch(API_URL_BEFORE + this.state.domain + API_URL_2 + this.state.part + API_URL_3 + '/token', {
            method: "POST",
            body: JSON.stringify({
                token: localStorage.getItem('ga_auth_key')
            }),
        }).then((response) => response.json()).then((response) => {
            if(response.valid === 0){
                localStorage.setItem('access', 'limited');
                this.setGlobal({
                    access: 'limited'
                });
                this.setState({
                    access: 'limited'
                });
            }

            this.getRequests();

            if(response.token !== undefined){
                if(response.token === 'refresh'){
                    let room  = localStorage.getItem('room');
                    let domain  = localStorage.getItem('domain');
                    window.location.href = 'https://concierge.hkeeper.global?r=' + room + '&d=' + domain + '&p=global';
                }
            }else{
                if(isMobileSafari && isMobile && isIOS){
                    this.saveToken(localStorage.getItem('ga_auth_key'), this.state.access, this.state.domain, this.state.part, localStorage.getItem('room'));
                }else{
                    this.setState({
                        pwa: true
                    });
                }
            }
        });
    }

    sendRate = async () => {
        await fetch(API_URL_BEFORE + this.state.domain + API_URL_2 + this.state.part + API_URL_3 + '/requests', {
            method: "POST",
            body: JSON.stringify({
                uuid: this.state.requestId,
                message: this.state.comment,
                action: 'update',
                rate: this.state.rate
            }),
        }).then((response) => {
            this.setState({
                disabled: false,
                modal_rate: false,
                rate: 0,
                comment: ''
            }, ()=> {
                this.getRequests()
            });
        });
    }

    cancelRequest = async (uuid) => {
        await fetch(API_URL_BEFORE + this.state.domain + API_URL_2 + this.state.part + API_URL_3 + '/requests', {
            method: "POST",
            body: JSON.stringify({
                uuid: uuid,
                action: 'cancel'
            }),
        }).then((response) => {
            this.setState({
                disabled: false,
                modal_rate: false
            }, ()=> {
                this.getRequests()
            });
        });
    }

    showRate = () => {
        this.setState({
            modal_rate: true
        })
    }

    closeRate = () => {
        this.setState({
            modal_rate: false
        })
    }

    setOpenMode = (index) => {
        let req = this.state.requests;
        req[index].open = !req[index].open;
        this.setState({
            requests: req
        })
    }

    timeConverter = (UNIX_timestamp) => {
        let a = new Date(UNIX_timestamp * 1000);
        let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        let year = a.getFullYear();
        let month = months[a.getMonth()];
        let date = ('0' + a.getDate()).slice(-2);
        let hour = ('0' + a.getHours()).slice(-2);
        let min = ('0' + a.getMinutes()).slice(-2);
        let time = date + '-' + month + '-' + year + ' ' + hour + ':' + min;
        return time;
    }

    renderRequestComment = (request) => {
        if(request.guest_comments !== undefined && request.guest_comments !== '' && request.guest_comments !== null){
            return(
                <tr>
                    <td style={{width: '95px'}}>
                        <span>Comment:</span>
                    </td>
                    <td>
                        {request.guest_comments}
                    </td>
                </tr>
            )
        }
    }

    renderRequestCount = (request) => {
        if(request.widget === 'countable'){
            let options = JSON.parse(request.widget_options);
            return(
                <tr>
                    <td style={{width: '95px'}}>
                        <span>Quantity:</span>
                    </td>
                    <td>
                        {options.options}
                    </td>
                </tr>
            )
        }
    }

    renderRequestOptions = (request) => {
        if(request.widget === 'options'){
            let options = JSON.parse(request.widget_options);
            let options_str = '';
            if(options.options[0].options !== undefined){
                options.options.map((optG, indG) => {
                    optG.options.map((opt, ind) => {
                        if(opt.checked){
                            options_str += '<div>' + opt.title + '</div>'
                        }
                    });
                });
            }else{
                options.options.map((opt, ind) => {
                    if(opt.checked){
                        options_str += '<div>' + opt.title + '</div>'
                    }
                });
            }

            return(
                <tr>
                    <td style={{width: '95px'}}>
                        <span>Options:</span>
                    </td>
                    <td dangerouslySetInnerHTML={{__html: options_str}}/>
                </tr>
            )
        }
    }

    renderRequestOptionsCountable = (request) => {
        if(request.widget === 'options_countable'){
            let options = JSON.parse(request.widget_options);
            let options_str = '';
            if(options.options[0].options !== undefined){
                options.options.map((optG, indG) => {
                    optG.options.map((opt, ind) => {
                        if(parseInt(opt.count) > 0){
                            options_str += '<div>' + opt.title + ': <b>' + opt.count + '</b></div>'
                        }
                    });
                });
            }else{
                options.options.map((opt, ind) => {
                    if(parseInt(opt.count) > 0){
                        options_str += '<div>' + opt.title + ': <b>' + opt.count + '</b></div>'
                    }
                });
            }

            return(
                <tr>
                    <td style={{width: '95px'}}>
                        <span>Options:</span>
                    </td>
                    <td dangerouslySetInnerHTML={{__html: options_str}}/>
                </tr>
            )
        }
    }

    renderRequestTime = (request) => {
        if(request.widget === 'time'){
            return(
                <tr>
                    <td style={{width: '95px'}}>
                        <span>Scheduled:</span>
                    </td>
                    <td><b>{request.widget_date}</b></td>
                </tr>
            )
        }
    }

    renderRequestDateTime = (request) => {
        if(request.widget === 'date_time'){
            return(
                <tr>
                    <td style={{width: '95px'}}>
                        <span>Scheduled:</span>
                    </td>
                    <td><b>{request.widget_date}</b></td>
                </tr>
            )
        }
    }

    renderCancelBtn = (request) => {
        if(request.c_title !== 'Canceled' && request.c_title !== 'Completed' && request.c_title !== 'Refused' && request.c_title !== 'Cancelled'){
            return(
                <div className="actions__wrap actions__wrap-cancel d-flex">
                    <div onClick={()=>{
                        this.cancelRequest(request.uuid)
                    }} className="btn__custom btn__custom-cancel d-flex align-items-center justify-content-center">
                        Cancel
                    </div>
                </div>
            )
        }
    }

    renderRequestDetails = (request) => {
        if(request.refactor === 1){
            if(request.widget_data !== null){
                let searchRegExp1 = 'far fa-comment-dots';
                let searchRegExp2 = 'fa fa-fw fa-stopwatch mr-2';
                let searchRegExp3 = 'fa fa-check';
                let replaceWith1 = 'las la-comments';
                let replaceWith2 = 'las la-clock';
                let replaceWith3 = 'las la-check';

                let w_d = request.widget_data.split(searchRegExp1).join(replaceWith1);
                w_d = w_d.split(searchRegExp2).join(replaceWith2);
                w_d = w_d.split(searchRegExp3).join(replaceWith3);
                return(
                    <div className="request__html" dangerouslySetInnerHTML={{__html: w_d}}/>
                )
            }
        }else{
            return(
                <table>
                    <tr>
                        <td style={{width: '95px'}}>
                            <span>Created:</span>
                        </td>
                        <td>
                            {request.created}
                        </td>
                    </tr>
                    {
                        this.renderRequestComment(request)
                    }

                    {
                        this.renderRequestCount(request)
                    }

                    {
                        this.renderRequestOptions(request)
                    }
                    {
                        this.renderRequestOptionsCountable(request)
                    }
                    {
                        this.renderRequestTime(request)
                    }
                    {
                        this.renderRequestDateTime(request)
                    }
                </table>
            )
        }
    }

    renderUserRequests = () => {
        if(this.state.requests.length > 0){
            return(
                this.state.requests.map((request, i)=>(
                    <div className={'requests__item-wrap ' + ((request.open === true) ? 'requests__item-open' : '')}>
                        <div onClick={()=>{
                            this.setOpenMode(i)
                            /*if(request.completed === 1){
                                this.setState({
                                    requestId: request.uuid
                                }, ()=>{
                                    if(request.rate !== '' && request.rate !== null && request.rate !== undefined){
                                        this.setState({
                                            rate: parseInt(request.rate)
                                        });
                                    }
                                    this.showRate()
                                });
                            }*/

                        }}  className={'requests__item d-flex ' + ((request.completed === 1) ? 'requests__item-done modal-show ' : '') + ((request.rate !== '' && request.rate !== null && request.rate !== undefined) ? 'requests__item-rated ' : '')}>
                            <div className={'status__box d-flex align-items-center justify-content-center ' + ((request.completed === 1) ? 'status-finished' : '')} style={{color: request.color}}>
                                <div className="status__box-bg" style={{backgroundColor: request.color}}></div>
                                {
                                    this.renderRequestStatusTitle(request)
                                }
                            </div>
                            <div className="requests__item-title">{request.title}</div>
                            {
                                this.renderRequestRateLink(request)
                            }
                            {
                                this.renderStarsRate(request)
                            }
                            <div className="requests__item-id">#{request.id}</div>
                            <div className="link__next">
                                <i className="las la-angle-right"></i>
                            </div>
                        </div>
                        <div className="requests__item-detail fadeIn animated">
                            {
                                this.renderRequestDetails(request)
                            }
                            {
                                this.renderCancelBtn(request)
                            }
                        </div>
                    </div>
                ))
            )
        }else{
            return(
                <div className="empty__text">
                    You have no requests yet
                </div>
            )
        }
    }

    renderRequestStatusTitle = (request) => {
        return (
            <div className="status__box-title">{request.c_title}</div>
        )
    }

    renderStarsRate = (request) =>{
        if(request.rate !== '' && request.rate !== null && request.rate !== undefined){
            return(
                <div onClick={(event)=>{
                    if(request.completed === 1){
                        this.setState({
                            requestId: request.uuid
                        }, ()=>{
                            if(request.rate !== '' && request.rate !== null && request.rate !== undefined){
                                this.setState({
                                    rate: parseInt(request.rate)
                                });
                            }
                            this.showRate()
                        });
                    }
                    event.preventDefault();
                    event.stopPropagation();
                }} className="status__box-title stars__list d-flex justify-content-center align-items-center">
                    {[...Array(5)].map((e, i) => {
                        if((i + 1) <= request.rate){
                            return (
                                <div key={i}>
                                    <svg fill="#3ca0f1" viewBox="0 -10 511.99143 511" xmlns="http://www.w3.org/2000/svg">
                                        <path d="m510.652344 185.882812c-3.371094-10.367187-12.566406-17.707031-23.402344-18.6875l-147.796875-13.417968-58.410156-136.75c-4.3125-10.046875-14.125-16.53125-25.046875-16.53125s-20.738282 6.484375-25.023438 16.53125l-58.410156 136.75-147.820312 13.417968c-10.835938 1-20.011719 8.339844-23.402344 18.6875-3.371094 10.367188-.257813 21.738282 7.9375 28.925782l111.722656 97.964844-32.941406 145.085937c-2.410156 10.667969 1.730468 21.699219 10.582031 28.097656 4.757813 3.457031 10.347656 5.183594 15.957031 5.183594 4.820313 0 9.644532-1.28125 13.953125-3.859375l127.445313-76.203125 127.421875 76.203125c9.347656 5.585938 21.101562 5.074219 29.933593-1.324219 8.851563-6.398437 12.992188-17.429687 10.582032-28.097656l-32.941406-145.085937 111.722656-97.964844c8.191406-7.1875 11.308594-18.535156 7.9375-28.925782zm-252.203125 223.722657"/>
                                    </svg>
                                </div>
                            )
                        }else{
                            return (
                                <div key={i}>
                                    <svg fill="#3ca0f1" viewBox="0 -10 511.98685 511" xmlns="http://www.w3.org/2000/svg">
                                        <path d="m114.59375 491.140625c-5.609375 0-11.179688-1.75-15.933594-5.1875-8.855468-6.417969-12.992187-17.449219-10.582031-28.09375l32.9375-145.089844-111.703125-97.960937c-8.210938-7.167969-11.347656-18.519532-7.976562-28.90625 3.371093-10.367188 12.542968-17.707032 23.402343-18.710938l147.796875-13.417968 58.433594-136.746094c4.308594-10.046875 14.121094-16.535156 25.023438-16.535156 10.902343 0 20.714843 6.488281 25.023437 16.511718l58.433594 136.769532 147.773437 13.417968c10.882813.980469 20.054688 8.34375 23.425782 18.710938 3.371093 10.367187.253906 21.738281-7.957032 28.90625l-111.703125 97.941406 32.9375 145.085938c2.414063 10.667968-1.726562 21.699218-10.578125 28.097656-8.832031 6.398437-20.609375 6.890625-29.910156 1.300781l-127.445312-76.160156-127.445313 76.203125c-4.308594 2.558594-9.109375 3.863281-13.953125 3.863281zm141.398438-112.875c4.84375 0 9.640624 1.300781 13.953124 3.859375l120.277344 71.9375-31.085937-136.941406c-2.21875-9.746094 1.089843-19.921875 8.621093-26.515625l105.472657-92.5-139.542969-12.671875c-10.046875-.917969-18.6875-7.234375-22.613281-16.492188l-55.082031-129.046875-55.148438 129.066407c-3.882812 9.195312-12.523438 15.511718-22.546875 16.429687l-139.5625 12.671875 105.46875 92.5c7.554687 6.613281 10.859375 16.769531 8.621094 26.539062l-31.0625 136.9375 120.277343-71.914062c4.308594-2.558594 9.109376-3.859375 13.953126-3.859375zm-84.585938-221.847656s0 .023437-.023438.042969zm169.128906-.0625.023438.042969c0-.023438 0-.023438-.023438-.042969zm0 0"/>
                                    </svg>
                                </div>
                            )
                        }

                    })}
                </div>
            )
        }
    }

    renderRequestRateLink = (request) => {
        if(request.completed === 1 && (request.rate === '' || request.rate === null || request.rate === undefined)){
            return(
                <div className="rate__over-title" onClick={(event)=>{
                    if(request.completed === 1){
                        this.setState({
                            requestId: request.uuid
                        }, ()=>{
                            if(request.rate !== '' && request.rate !== null && request.rate !== undefined){
                                this.setState({
                                    rate: parseInt(request.rate)
                                });
                            }
                            this.showRate()
                        });
                    }
                    event.preventDefault();
                    event.stopPropagation();
                }}>Rate</div>
            )
        }
    }

    setRate = (rate) => {
        this.setState({
            rate: rate
        })
    }

    renderEmptyStar = () => {
        return(
            <div>
                <svg fill="#3ca0f1" viewBox="0 -10 511.98685 511" xmlns="http://www.w3.org/2000/svg">
                    <path d="m114.59375 491.140625c-5.609375 0-11.179688-1.75-15.933594-5.1875-8.855468-6.417969-12.992187-17.449219-10.582031-28.09375l32.9375-145.089844-111.703125-97.960937c-8.210938-7.167969-11.347656-18.519532-7.976562-28.90625 3.371093-10.367188 12.542968-17.707032 23.402343-18.710938l147.796875-13.417968 58.433594-136.746094c4.308594-10.046875 14.121094-16.535156 25.023438-16.535156 10.902343 0 20.714843 6.488281 25.023437 16.511718l58.433594 136.769532 147.773437 13.417968c10.882813.980469 20.054688 8.34375 23.425782 18.710938 3.371093 10.367187.253906 21.738281-7.957032 28.90625l-111.703125 97.941406 32.9375 145.085938c2.414063 10.667968-1.726562 21.699218-10.578125 28.097656-8.832031 6.398437-20.609375 6.890625-29.910156 1.300781l-127.445312-76.160156-127.445313 76.203125c-4.308594 2.558594-9.109375 3.863281-13.953125 3.863281zm141.398438-112.875c4.84375 0 9.640624 1.300781 13.953124 3.859375l120.277344 71.9375-31.085937-136.941406c-2.21875-9.746094 1.089843-19.921875 8.621093-26.515625l105.472657-92.5-139.542969-12.671875c-10.046875-.917969-18.6875-7.234375-22.613281-16.492188l-55.082031-129.046875-55.148438 129.066407c-3.882812 9.195312-12.523438 15.511718-22.546875 16.429687l-139.5625 12.671875 105.46875 92.5c7.554687 6.613281 10.859375 16.769531 8.621094 26.539062l-31.0625 136.9375 120.277343-71.914062c4.308594-2.558594 9.109376-3.859375 13.953126-3.859375zm-84.585938-221.847656s0 .023437-.023438.042969zm169.128906-.0625.023438.042969c0-.023438 0-.023438-.023438-.042969zm0 0"/>
                </svg>
            </div>
        )
    }

    renderFullStar = () => {
        return(
            <div>
                <svg height="511pt" fill="#3ca0f1" viewBox="0 -10 511.99143 511" width="511pt" xmlns="http://www.w3.org/2000/svg">
                    <path d="m510.652344 185.882812c-3.371094-10.367187-12.566406-17.707031-23.402344-18.6875l-147.796875-13.417968-58.410156-136.75c-4.3125-10.046875-14.125-16.53125-25.046875-16.53125s-20.738282 6.484375-25.023438 16.53125l-58.410156 136.75-147.820312 13.417968c-10.835938 1-20.011719 8.339844-23.402344 18.6875-3.371094 10.367188-.257813 21.738282 7.9375 28.925782l111.722656 97.964844-32.941406 145.085937c-2.410156 10.667969 1.730468 21.699219 10.582031 28.097656 4.757813 3.457031 10.347656 5.183594 15.957031 5.183594 4.820313 0 9.644532-1.28125 13.953125-3.859375l127.445313-76.203125 127.421875 76.203125c9.347656 5.585938 21.101562 5.074219 29.933593-1.324219 8.851563-6.398437 12.992188-17.429687 10.582032-28.097656l-32.941406-145.085937 111.722656-97.964844c8.191406-7.1875 11.308594-18.535156 7.9375-28.925782zm-252.203125 223.722657"/>
                </svg>
            </div>
        )
    }

    render() {
        return (
            <div className={'page page__content animated fadeIn ' + ((this.state.loaded === true) ? 'loaded' : '')}>
                <div className="header d-flex align-items-center justify-content-center">
                    <div className="page__title">
                        {this.state.page_title}
                    </div>
                </div>
                <div className="content">
                    <div className="content__scroll">
                        <div className="container">
                            <div className="section">
                                <div className="requests__list requests__list-new">
                                    {
                                        this.renderUserRequests()
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal-rate" className={'modal ' + ((this.state.modal_rate) ? 'active' : '')}>
                    <div className="modal__box">
                        <div onClick={()=>{
                            this.closeRate()
                        }} className="close__modal">
                            <i className="las la-times" />
                        </div>
                        <div className="modal__box-title page__title">Rate our service</div>
                        <div className="form__custom">
                            <div className="form__item">
                                <div className="form__field">
                                        <textarea onChange={(comment)=>{
                                            let ranges = '\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]';
                                            let str = comment.target.value;
                                            str = str.replace(new RegExp(ranges, 'g'), '');
                                            this.setState({comment: str});
                                        }} value={this.state.comment} placeholder="Leave your comment" defaultValue={""} />
                                </div>
                            </div>
                            <div className="form__item">
                                <div className="form__field d-flex align-items-center justify-content-center">
                                    <div onClick={()=>{
                                        if(this.state.rate === 1){
                                            this.setRate(0)
                                        }else{
                                            this.setRate(1)
                                        }
                                    }} className={'rate__item ' + ((this.state.rate > 0) ? 'active' : '')}>
                                        {(this.state.rate > 0) ? this.renderFullStar() : this.renderEmptyStar()}
                                    </div>
                                    <div onClick={()=>{
                                        if(this.state.rate === 2){
                                            this.setRate(0)
                                        }else{
                                            this.setRate(2)
                                        }
                                    }} className={'rate__item ' + ((this.state.rate > 1) ? 'active' : '')}>
                                        {(this.state.rate > 1) ? this.renderFullStar() : this.renderEmptyStar()}
                                    </div>
                                    <div onClick={()=>{
                                        if(this.state.rate === 3){
                                            this.setRate(0)
                                        }else{
                                            this.setRate(3)
                                        }
                                    }} className={'rate__item ' + ((this.state.rate > 2) ? 'active' : '')}>
                                        {(this.state.rate > 2) ? this.renderFullStar() : this.renderEmptyStar()}
                                    </div>
                                    <div onClick={()=>{
                                        if(this.state.rate === 4){
                                            this.setRate(0)
                                        }else{
                                            this.setRate(4)
                                        }
                                    }} className={'rate__item ' + ((this.state.rate > 3) ? 'active' : '')}>
                                        {(this.state.rate > 3) ? this.renderFullStar() : this.renderEmptyStar()}
                                    </div>
                                    <div onClick={()=>{
                                        if(this.state.rate === 5){
                                            this.setRate(0)
                                        }else{
                                            this.setRate(5)
                                        }
                                    }} className={'rate__item ' + ((this.state.rate > 4) ? 'active' : '')}>
                                        {(this.state.rate > 4) ? this.renderFullStar() : this.renderEmptyStar()}
                                    </div>
                                </div>
                            </div>
                            <div className={'form__item form__item-actions ' + ((this.state.disabled === true) ? 'disabled' : '')}>
                                <div onClick={()=>{
                                    this.setState({
                                        disabled: true
                                    });
                                    this.sendRate()
                                }} className="btn__custom d-flex align-items-center justify-content-center show__code">
                                    Submit
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer updateRequests={this.updateRequests} chatnot={this.state.chatnot} reqnot={this.state.reqnot} access={this.state.access} page={'requests'}/>
            </div>
        )

    }
}
