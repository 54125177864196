import React, { setGlobal } from 'reactn'
import { Link } from 'react-router-dom'
import Notify from './Notify'
import "../assets/css/bootstrap.min.css"
import "../assets/fonts/line-awesome-1.3.0/1.3.0/css/line-awesome.min.css"
import {
    isAndroid,
    isIOS,
} from "react-device-detect"
import Pusher from 'pusher-js'

const pusher = new Pusher('1eb13f7e7371ca4e6cae', {
    cluster: 'mt1'
});

let audio;
if(isIOS){
    audio = new Audio();
}else{
    audio = new Audio('push.mp3');
}

export default class Footer extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            notify:  '',
            chatnot: false,
            reqnot: false,
            playState: ''
        }
    }

    componentDidMount() {
        this.setGlobal({
            access: 'full'
        });

        if(localStorage.getItem('access')){
            this.setGlobal({
                access: localStorage.getItem('access')
            });
        }

        audio.addEventListener('play', (event) => {
            this.setState({
                playState: 'push event play'
            });
        });

        audio.addEventListener('waiting', (event) => {
            this.setState({
                playState: 'push event waiting'
            });
        });

        audio.addEventListener('error', (event) => {
            this.setState({
                playState: 'push event error'
            });
        });

        audio.addEventListener('emptied', (event) => {
            /*if(isIOS){
                audio = new Audio();
            }else{
                audio = new Audio('push.mp3');
            }*/
            this.setState({
                playState: 'push event emptied'
            });
        });


        audio.addEventListener('canplay', (event) => {
            this.setState({
                playState: 'push event canplay'
            });
        });


        if(localStorage.getItem('ga_auth_key')){
            let channel = pusher.subscribe(localStorage.getItem('ga_auth_key'));
            channel.bind('guest_request', (data) => {
                audio.src = 'push.mp3';
                audio.play();

                if(this.props.page !== 'requests'){
                    this.setState({
                        reqnot: true,
                        notify: data
                    });
                    this.setGlobal({
                        reqnot: true
                    });

                    setTimeout(()=>{
                        this.setState({
                            notify: ''
                        });
                    }, 4000);
                }

                if(this.props.page === 'requests'){
                    this.props.updateRequests()
                }

            });
            channel.bind('chat', (data) => {
                audio.src = 'push.mp3';
                audio.play();
                if(this.props.page !== 'chat'){
                    this.setState({
                        chatnot: true,
                        notify: data
                    });

                    this.setGlobal({
                        chatnot: true
                    });

                    setTimeout(()=>{
                        this.setState({
                            notify: ''
                        });
                    }, 4000);
                }

                if(this.props.page === 'chat'){
                    this.props.updateChat()
                }
            });
        }

    }

    renderAmenityLink = () => {
        if(this.global.access === 'full'){
            return(
                <Link onClick={(this.global.page === 'amenities') ? this.forceUpdate : ""} to="/amenities" className={'footer__col d-flex align-items-center justify-content-center ' + ((this.global.page === 'amenities') ? 'active' : '')}>
                    <div className="bell__icon">
                        <svg height="512" viewBox="0 0 64 64" width="512" xmlns="http://www.w3.org/2000/svg">
                            <path d="m57 48h-20v-2h21a1 1 0 0 0 1-1 5.017 5.017 0 0 0 -4-4.9v-1.1a19.017 19.017 0 0 0 -19-19v-3a1 1 0 0 0 -1-1h-2v-2h3a1 1 0 0 0 1-1 5 5 0 0 0 -10 0 1 1 0 0 0 1 1h3v2h-2a1 1 0 0 0 -1 1v3a19.017 19.017 0 0 0 -19 19v1.1a5.017 5.017 0 0 0 -4 4.9 1 1 0 0 0 1 1h21v2h-20a5 5 0 0 0 -5 5v1a2.006 2.006 0 0 0 2 2h56a2.006 2.006 0 0 0 2-2v-1a5 5 0 0 0 -5-5zm-27.83-36a3 3 0 0 1 5.66 0zm.83 6h4v2h-4zm-19 21a17.024 17.024 0 0 1 17-17h8a17.024 17.024 0 0 1 17 17v1h-42zm-3.83 5a3.014 3.014 0 0 1 2.83-2h44a3.014 3.014 0 0 1 2.83 2zm27.83 2v2h-6v-2zm25 8h-56v-1a3.009 3.009 0 0 1 3-3h50a3.009 3.009 0 0 1 3 3z"/>
                            <path d="m14.69 17.846-1.068-1.692a26.987 26.987 0 0 0 -10.545 12.46l1.846.772a24.981 24.981 0 0 1 9.767-11.54z"/>
                            <path d="m6.574 30.635 1.862.73a21.279 21.279 0 0 1 8.111-9.96l-1.094-1.674a23.293 23.293 0 0 0 -8.879 10.904z"/>
                            <path d="m59.077 29.386 1.846-.772a26.987 26.987 0 0 0 -10.545-12.46l-1.068 1.692a24.981 24.981 0 0 1 9.767 11.54z"/>
                            <path d="m55.564 31.365 1.862-.73a23.293 23.293 0 0 0 -8.879-10.9l-1.094 1.674a21.279 21.279 0 0 1 8.111 9.956z"/>
                        </svg>
                    </div>
                </Link>
            )
        }else{
            return(
                <Link to="/login" style={{opacity: 0.5}} className={'footer__col d-flex align-items-center justify-content-center ' + ((this.global.page === 'amenities') ? 'active' : '')}>
                    <div className="bell__icon">
                        <svg height="512" viewBox="0 0 64 64" width="512" xmlns="http://www.w3.org/2000/svg">
                            <path d="m57 48h-20v-2h21a1 1 0 0 0 1-1 5.017 5.017 0 0 0 -4-4.9v-1.1a19.017 19.017 0 0 0 -19-19v-3a1 1 0 0 0 -1-1h-2v-2h3a1 1 0 0 0 1-1 5 5 0 0 0 -10 0 1 1 0 0 0 1 1h3v2h-2a1 1 0 0 0 -1 1v3a19.017 19.017 0 0 0 -19 19v1.1a5.017 5.017 0 0 0 -4 4.9 1 1 0 0 0 1 1h21v2h-20a5 5 0 0 0 -5 5v1a2.006 2.006 0 0 0 2 2h56a2.006 2.006 0 0 0 2-2v-1a5 5 0 0 0 -5-5zm-27.83-36a3 3 0 0 1 5.66 0zm.83 6h4v2h-4zm-19 21a17.024 17.024 0 0 1 17-17h8a17.024 17.024 0 0 1 17 17v1h-42zm-3.83 5a3.014 3.014 0 0 1 2.83-2h44a3.014 3.014 0 0 1 2.83 2zm27.83 2v2h-6v-2zm25 8h-56v-1a3.009 3.009 0 0 1 3-3h50a3.009 3.009 0 0 1 3 3z"/>
                            <path d="m14.69 17.846-1.068-1.692a26.987 26.987 0 0 0 -10.545 12.46l1.846.772a24.981 24.981 0 0 1 9.767-11.54z"/>
                            <path d="m6.574 30.635 1.862.73a21.279 21.279 0 0 1 8.111-9.96l-1.094-1.674a23.293 23.293 0 0 0 -8.879 10.904z"/>
                            <path d="m59.077 29.386 1.846-.772a26.987 26.987 0 0 0 -10.545-12.46l-1.068 1.692a24.981 24.981 0 0 1 9.767 11.54z"/>
                            <path d="m55.564 31.365 1.862-.73a23.293 23.293 0 0 0 -8.879-10.9l-1.094 1.674a21.279 21.279 0 0 1 8.111 9.956z"/>
                        </svg>
                    </div>
                </Link>
            )
        }
    }

    renderRequestsLink = () => {
        if(this.global.access === 'full'){
            return(
                <Link  onClick={(this.global.page === 'requests') ? this.forceUpdate : ""} to="/requests" className={'footer__col d-flex align-items-center justify-content-center ' + ((this.global.page === 'requests') ? 'active ' : '') + ((this.state.reqnot === true) ? 'notify' : '')}>
                    <div className="requests__icon">
                        <svg height="512" viewBox="0 0 32 32" width="512" xmlns="http://www.w3.org/2000/svg">
                            <path d="m26 10.5a.5.5 0 0 0 -.5.5v12.91a1.59 1.59 0 0 1 -1.59 1.59h-15.82a1.59 1.59 0 0 1 -1.59-1.59v-15.82a1.59 1.59 0 0 1 1.59-1.59h12.91a.5.5 0 0 0 0-1h-12.91a2.59 2.59 0 0 0 -2.59 2.59v15.82a2.59 2.59 0 0 0 2.59 2.59h15.82a2.59 2.59 0 0 0 2.59-2.59v-12.91a.5.5 0 0 0 -.5-.5z"/>
                            <path d="m11.35 14.65a.49.49 0 0 0 -.7.7l3 3a.48.48 0 0 0 .7 0l10-10a.49.49 0 1 0 -.7-.7l-9.65 9.64z"/>
                        </svg>
                    </div>
                </Link>
            )
        }else{
            return(
                <Link to="/login" style={{opacity: 0.5}} className={'footer__col d-flex align-items-center justify-content-center ' + ((this.global.page === 'requests') ? 'active' : '')}>
                    <div className="requests__icon">
                        <svg height="512" viewBox="0 0 32 32" width="512" xmlns="http://www.w3.org/2000/svg">
                            <path d="m26 10.5a.5.5 0 0 0 -.5.5v12.91a1.59 1.59 0 0 1 -1.59 1.59h-15.82a1.59 1.59 0 0 1 -1.59-1.59v-15.82a1.59 1.59 0 0 1 1.59-1.59h12.91a.5.5 0 0 0 0-1h-12.91a2.59 2.59 0 0 0 -2.59 2.59v15.82a2.59 2.59 0 0 0 2.59 2.59h15.82a2.59 2.59 0 0 0 2.59-2.59v-12.91a.5.5 0 0 0 -.5-.5z"/>
                            <path d="m11.35 14.65a.49.49 0 0 0 -.7.7l3 3a.48.48 0 0 0 .7 0l10-10a.49.49 0 1 0 -.7-.7l-9.65 9.64z"/>
                        </svg>
                    </div>
                </Link>
            )
        }
    }

    play = () => {
        if(audio.src === '' || audio.src === null || audio.src  === undefined){
            audio.play();
        }

    }

    render() {
        return (
            <div>
                <div className="footer" onClick={()=>{this.play()}}>
                    {/*<div style={{position: 'absolute', left: 0, bottom: '100%', width: '100%', textAlign: 'center'}}>
                        {this.state.playState}
                    </div>*/}
                    <div className="row m-0">
                        <Link onClick={(this.global.page === 'info') ? this.forceUpdate : ""} to="/info" className={'footer__col d-flex align-items-center justify-content-center ' + ((this.global.page === 'info') ? 'active' : '')}>
                            <div className="info__icon">
                                <svg height="512" viewBox="0 0 64 64" width="512" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m32.387 49.5a3.618 3.618 0 0 0 3.613-3.614v-17.772a3.618 3.618 0 0 0 -3.613-3.614h-4.187a3.614 3.614 0 0 0 0 7.227h.568v14.159a3.619 3.619 0 0 0 3.619 3.614zm-4.187-19.773a1.614 1.614 0 0 1 0-3.227h4.183a1.616 1.616 0 0 1 1.617 1.614v17.772a1.614 1.614 0 1 1 -3.228 0v-16.159z"/>
                                    <path d="m32 14.5a4 4 0 1 0 4 4 4 4 0 0 0 -4-4zm0 6a2 2 0 1 1 2-2 2 2 0 0 1 -2 2z"/>
                                    <path d="m32 8a24 24 0 0 0 0 48 23.746 23.746 0 0 0 14.941-5.217 1 1 0 1 0 -1.241-1.565 21.757 21.757 0 0 1 -13.7 4.782 22 22 0 1 1 16.678-7.652 1 1 0 1 0 1.515 1.3 24 24 0 0 0 -18.193-39.648z"/>
                                </svg>
                            </div>
                        </Link>
                        <Link onClick={(this.global.page === 'chat') ? this.forceUpdate : ""} to="/chat" className={'footer__col d-flex align-items-center justify-content-center ' + ((this.global.page === 'chat') ? 'active ' : '') + ((this.global.chatnot === true) ? 'notify' : '')}>
                            <div className="chat__icon">
                                <svg height="512" viewBox="0 0 64 64" width="512" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m59.036 18.473h-14.25v-4.937a3.254 3.254 0 0 0 -3.25-3.25h-35.286a3.254 3.254 0 0 0 -3.25 3.25v23.356a3.254 3.254 0 0 0 3.25 3.25h1.464v7.928a1 1 0 0 0 1.686.73l9.284-8.66h1.816v4.937a3.254 3.254 0 0 0 3.25 3.25h22.856l9.284 8.659a1 1 0 0 0 .681.269 1.012 1.012 0 0 0 .4-.083 1 1 0 0 0 .6-.917v-7.926h1.465a3.254 3.254 0 0 0 3.25-3.25v-23.356a3.254 3.254 0 0 0 -3.25-3.25zm-40.75 19.669a1 1 0 0 0 -.682.269l-7.89 7.359v-6.628a1 1 0 0 0 -1-1h-2.464a1.251 1.251 0 0 1 -1.25-1.25v-23.356a1.252 1.252 0 0 1 1.25-1.25h35.286a1.251 1.251 0 0 1 1.25 1.25v23.356a1.25 1.25 0 0 1 -1.25 1.25zm42 6.937a1.251 1.251 0 0 1 -1.25 1.25h-2.465a1 1 0 0 0 -1 1v6.628l-7.889-7.357a1 1 0 0 0 -.682-.271h-23.25a1.252 1.252 0 0 1 -1.25-1.25v-4.937h19.036a3.254 3.254 0 0 0 3.25-3.25v-16.419h14.25a1.25 1.25 0 0 1 1.25 1.25z"/>
                                    <path d="m23.893 23.265a1.887 1.887 0 1 0 1.887 1.887 1.887 1.887 0 0 0 -1.887-1.887z"/>
                                    <path d="m34.339 23.265a1.887 1.887 0 1 0 1.887 1.887 1.887 1.887 0 0 0 -1.887-1.887z"/>
                                    <path d="m13.446 23.265a1.887 1.887 0 1 0 1.887 1.887 1.886 1.886 0 0 0 -1.887-1.887z"/>
                                    <path d="m47.328 27.579h8.958v2h-8.958z"/>
                                    <path d="m47.328 32.4h8.958v2h-8.958z"/>
                                    <path d="m47.328 37.223h8.958v2h-8.958z"/>
                                </svg>
                            </div>
                        </Link>
                        {
                            this.renderAmenityLink()
                        }
                        <Link  onClick={(this.global.page === 'requests') ? this.forceUpdate : ""} to="/requests" className={'footer__col d-flex align-items-center justify-content-center ' + ((this.global.page === 'requests') ? 'active ' : '') + ((this.global.reqnot === true) ? 'notify' : '')}>
                            <div className="requests__icon">
                                <svg height="512" viewBox="0 0 32 32" width="512" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m26 10.5a.5.5 0 0 0 -.5.5v12.91a1.59 1.59 0 0 1 -1.59 1.59h-15.82a1.59 1.59 0 0 1 -1.59-1.59v-15.82a1.59 1.59 0 0 1 1.59-1.59h12.91a.5.5 0 0 0 0-1h-12.91a2.59 2.59 0 0 0 -2.59 2.59v15.82a2.59 2.59 0 0 0 2.59 2.59h15.82a2.59 2.59 0 0 0 2.59-2.59v-12.91a.5.5 0 0 0 -.5-.5z"/>
                                    <path d="m11.35 14.65a.49.49 0 0 0 -.7.7l3 3a.48.48 0 0 0 .7 0l10-10a.49.49 0 1 0 -.7-.7l-9.65 9.64z"/>
                                </svg>
                            </div>
                        </Link>


                        {/*<div onClick={()=>{this.logOut()}} className="footer__col d-flex align-items-center justify-content-center">
                        <i className="las la-sign-out-alt" />
                    </div>*/}
                    </div>
                </div>
                <Notify notify={this.state.notify}/>
            </div>
        )
    }
}