import React, { setGlobal } from 'reactn'
import { Link, Redirect } from 'react-router-dom'
import Footer from './Footer'
import Notify from './Notify'
import {Helmet} from "react-helmet";
import "../assets/css/style.css"
import {
    isMobile,
    isIOS,
    isMobileSafari,
    isAndroid
} from "react-device-detect";
const API_URL_BEFORE = 'https://';
const API_URL_2 = '.hkeeper.';
const API_URL_3 = '/hk/ga_api';

let sl_set = {};

export default class Home extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            access: 'limited',
            token: '',
            room: '',
            domain: '',
            info_img: null,
            property: '',
            logo: '',
            welcome_title: 'Hello there!',
            chatnot: false,
            reqnot: false,
            notify: '',
            start: false,
            pwa: false,
            part: '',
            boarding: [],
            boardingShow: false,
            saveUser: true,
            loading: false,
            boardingSlide: 0,
            text_welcome: '',
            test_text: ''
        }
    }

    componentDidMount = async()  =>{
        this.setGlobal({
            page: '',
            chatnot: false,
            reqnot: false
        });

        document.getElementById('root').setAttribute('style','height:' + window.innerHeight + 'px');
        if(localStorage.getItem('access')){
            this.setState({
                access: localStorage.getItem('access')
            });

            this.setGlobal({
                access: localStorage.getItem('access')
            });
        }
        if(localStorage.getItem('start')){
            this.setState({
                start: false
            })
        }else{
            this.setState({
                start: true
            })
        }

        if(localStorage.getItem('boarding')){
            this.setState({
                boardingShow: false
            });
        }
        let urlParams = new URLSearchParams(this.props.location.search);
        let room = urlParams.get('r');
        let domain = urlParams.get('d');
        let part = urlParams.get('p');

        if(domain === undefined || domain === null || domain === ''){
            if(localStorage.getItem('ga_auth_key')){
                this.setState({
                    room: localStorage.getItem('room'),
                    domain: localStorage.getItem('domain'),
                    part: localStorage.getItem('part')
                }, () =>{
                    this.getInfo();
                })

            }else{
                if(isMobile && isIOS){
                    this.getToken();
                }
            }
        }else{
            if(room !== undefined && room !== null && room !== ''){
                localStorage.setItem('room', room);
                this.setState({
                    room: room
                });
            }
            if(part !== undefined && part !== null && part !== ''){
                if(part !== localStorage.getItem('part')) {
                    this.setState({
                        saveUser: false
                    });
                }
                localStorage.setItem('part', part);
                this.setState({
                    part: part
                });
            }else{
                localStorage.setItem('part', 'global');
                this.setState({
                    part: 'global'
                })
            }

            if(domain !== localStorage.getItem('domain')) {
                this.setState({
                    saveUser: false
                });
            }
            localStorage.setItem('domain', domain);
            this.setState({
                domain: domain
            }, ()=>{
                this.validateUser();
                this.getInfo();
            });
        }

        if(isMobile && isIOS){
            if(window.navigator.standalone){
                this.setState({
                    pwa: false,
                    boardingSlide: 1
                })
            }
        }

        if(isMobile && isAndroid){
            if(window.matchMedia('(display-mode: standalone)').matches){
                this.setState({
                    pwa: false,
                    boardingSlide: 1
                });
            }
        }


        this.getJSON('/manifest.json',
            function(err, data) {
                if (err != null) {
                    alert('Something went wrong: ' + err);
                } else {
                    let read = JSON.stringify(data);
                    console.log('Your query count: ', read);
                }
            });
    }

    getJSON = (url, callback) => {
        let xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'json';
        xhr.onload = function() {
            var status = xhr.status;
            if (status == 200) {
                callback(null, xhr.response);
            } else {
                callback(status);
            }
        };
        xhr.send();
    }

    saveToken = async (token, access, domain, part, room, property) => {
       /* if(isAndroid) {
            document.querySelector('#my-manifest-placeholder').setAttribute('href', '/manifest/' + token + '.' + domain + '.' + part + '.' + access+ '.' + room + '.' + property + '.json');
        }else{*/
            let manifest = {
                "short_name": property,
                "name": property,
                "icons": [
                    {
                        "src": "\/android-icon-36x36.png",
                        "sizes": "36x36",
                        "type": "image\/png",
                        "density": "0.75"
                    },
                    {
                        "src": "\/android-icon-48x48.png",
                        "sizes": "48x48",
                        "type": "image\/png",
                        "density": "1.0"
                    },
                    {
                        "src": "\/android-icon-72x72.png",
                        "sizes": "72x72",
                        "type": "image\/png",
                        "density": "1.5"
                    },
                    {
                        "src": "\/android-icon-96x96.png",
                        "sizes": "96x96",
                        "type": "image\/png",
                        "density": "2.0"
                    },
                    {
                        "src": "\/android-icon-144x144.png",
                        "sizes": "144x144",
                        "type": "image\/png",
                        "density": "3.0"
                    },
                    {
                        "src": "\/android-icon-192x192.png",
                        "sizes": "192x192",
                        "type": "image\/png",
                        "density": "4.0"
                    }
                ],
                "start_url": 'https://concierge.hkeeper.global/?source=pwa&suuid=' + token + '&sd=' + domain + '&sp=' + part + '&saccess=' + access + '&sroom=' + room,
                "orientation": "portrait",
                "display": "standalone",
                "theme_color": "#f8fafb",
                "background_color": "#ffffff"
            }

            let content = encodeURIComponent(JSON.stringify(manifest));
            let url = "data:application/manifest+json,"+content;
            let element = document.createElement('link');
            element.setAttribute('rel', 'manifest');
            element.setAttribute('href', url);
            document.querySelector('head').appendChild(element);
            document.title = property;
     /*   }*/

        if(!window.navigator.standalone){
            setTimeout(()=>{
                this.setState({
                    pwa: true
                });
            }, 700);
        }
    };

    getToken = async () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const token = urlParams.get('suuid');
        const domain = urlParams.get('sd');
        const part = urlParams.get('sp');
        const access = urlParams.get('saccess');
        const room = urlParams.get('sroom');

        localStorage.setItem('ga_auth_key', token);
        localStorage.setItem('access', access);
        localStorage.setItem('domain', domain);
        localStorage.setItem('part', part);
        localStorage.setItem('room', room);

        this.setState({
            access: access,
            domain: domain,
            part: part,
            room: room
        }, ()=>{
            this.getInfo();
            window.history.pushState({}, document.title, window.location.href.split('?')[0]);
        });
    };

    componentWillMount(){
        sl_set = {
            infinite: false,
            dots: true,
            swipeToSlide: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 800
        }
    }

    validateUser = async () => {
        let data = {
            room: this.state.room
        };
        if(localStorage.getItem('ga_auth_key') && this.state.saveUser === true){
            data['uuid'] = localStorage.getItem('ga_auth_key')
        }
        await fetch(API_URL_BEFORE + this.state.domain + API_URL_2 + this.state.part + API_URL_3 + '/validation', {
            method: "POST",
            mode: 'cors',
            body: JSON.stringify(data),
            credentials: 'include'
        }).then((response) => response.json()).then((response) => {
            this.setState({
                property: response.property,
                logo: response.logo,
            });
            if(response.access !== undefined && response.access !== null){
                localStorage.setItem('access', response.access);
                this.setGlobal({
                    access: response.access
                });

                localStorage.setItem('ga_auth_key', response.uuid);
                this.setState({
                    token: response.uuid,
                    access: response.access
                });
                if(isMobileSafari && isMobile && isIOS){
                    this.saveToken(response.uuid, response.access, this.state.domain, this.state.part, this.state.room, response.property);
                }else{
                    this.setState({
                        pwa: true
                    });
                }

                localStorage.setItem('timezone', response.timezone);

                this.setGlobal({
                    access: response.access
                });
                this.setState({
                    access: response.access
                }, ()=>{
                    //this.getInformersCat();
                    if(response.access === 'full'){
                        this.getAmenitiesCat();
                    }
                    window.history.pushState({}, document.title, window.location.href.split('?')[0]);
                });
            }
        });
    }

    getAmenitiesCat = async () => {
        await fetch(API_URL_BEFORE + this.state.domain + API_URL_2 + this.state.part + API_URL_3 + '/amenities', {
            method: "GET"
        }).then((response) => response.json()).then((response) => {
            localStorage.setItem('amenities', JSON.stringify(response));
        });
    }

    getInfo = async () => {
        this.setState({
            loading: true
        });
        await fetch(API_URL_BEFORE + this.state.domain + API_URL_2 + this.state.part + API_URL_3 + '/info', {
            method: "GET"
        }).then((response) => response.json()).then((response) => {
            this.setState({
                welcome_title: response.text_welcome,
                info_img: response.image,
                property: response.property,
                text_welcome: response.text_property,
                logo: response.logo,
                loading: false
            }, ()=>{
                localStorage.setItem('info_img', response.image);
                localStorage.setItem('text_amenities', response.text_amenities);
                if(response.descr_amenities !== undefined){
                    localStorage.setItem('descr_amenities', response.descr_amenities);
                }
            });

            if(response.onboarding !== undefined){
                if(!localStorage.getItem('boarding') || (isMobile && isIOS && !window.navigator.standalone)){
                    this.setState({
                        boarding: response.onboarding,
                        boardingShow: true
                    });
                }
            }
        });
    }

    renderOnBoarding = () =>{
        if(this.state.pwa || this.state.boardingShow){
            return(
                <div className={'pwa__install d-flex active ' + (this.state.pwa && isIOS ? 'slide--ios ' : '') + (this.state.pwa && isAndroid ? 'slide--android ' : '') + (this.state.boardingSlide !== 0 ? 'slide--boarding slide--' + this.state.boardingSlide : '')}>
                    <div className="pwa__install-bg"/>
                    {this.renderPwaScreen()}
                    {this.renderBoardingSlides()}
                </div>
            )
        }

        if(this.state.boarding.length > 0 && this.state.boardingSlide > 0){
            return(
                <div className="pwa__install-box">
                    {this.state.boarding[this.state.boardingSlide - 1].description}
                    <div className="actions__wrap actions__wrap-indent d-flex justify-content-center">
                        <div onClick={()=>{
                            this.setState({
                                boardingSlide: this.state.boardingSlide + 1
                            });
                        }} className="btn__custom d-flex align-items-center justify-content-center">
                            Next
                        </div>
                    </div>
                </div>
            )
        }
    }

    renderPwaScreen = () => {
        if(isMobile && this.state.pwa){
            if(isIOS){
                if(window.navigator.standalone === false || window.navigator.standalone === undefined){
                    return(
                        <div className="pwa__install-box pwa__install-ios animated fadeIn active">
                            To stay in touch with us save the Concierge webpage on your iPhone: tap
                            <i className="ios__share-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 55" version="1.1" x="0px" y="0px">
                                    <path d="M0,12 L0,44 L32,44 L32,12 L23,12 L23,14 L30,14 L30,42 L2,42 L2,14 L9,14 L9,12 L0,12 Z M16,0 L6,6 L6,8 L15.003125,2.5 L15.0031252,28 L17,28 L17,2.5 L26,8 L26,6 L16,0 Z M16,0" fill="#3ca0f1"/>
                                </svg>
                            </i>, scroll up and then Add to the home screen
                            <i className="ios__add-icon">
                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="612px" height="612px" viewBox="0 0 612 612">
                                    <path d="M420.75,286.875h-95.625V191.25c0-10.557-8.568-19.125-19.125-19.125c-10.557,0-19.125,8.568-19.125,19.125v95.625 H191.25c-10.557,0-19.125,8.568-19.125,19.125c0,10.557,8.568,19.125,19.125,19.125h95.625v95.625 c0,10.557,8.568,19.125,19.125,19.125c10.557,0,19.125-8.568,19.125-19.125v-95.625h95.625c10.557,0,19.125-8.568,19.125-19.125 C439.875,295.443,431.307,286.875,420.75,286.875z M535.5,0h-459C34.253,0,0,34.253,0,76.5v459C0,577.747,34.253,612,76.5,612 h459c42.247,0,76.5-34.253,76.5-76.5v-459C612,34.253,577.747,0,535.5,0z M573.75,535.5c0,21.133-17.136,38.25-38.25,38.25h-459 c-21.133,0-38.25-17.117-38.25-38.25v-459c0-21.133,17.117-38.25,38.25-38.25h459c21.114,0,38.25,17.136,38.25,38.25V535.5z"/>
                                </svg>
                            </i>
                            <div className="actions__wrap actions__wrap-indent d-flex justify-content-center">
                                <div onClick={()=>{
                                    this.setState({
                                        boardingSlide: this.state.boardingSlide + 1,
                                        pwa: false
                                    });

                                }} className="btn__custom d-flex align-items-center justify-content-center">
                                    {(this.state.boarding.length > 0 && this.state.boardingShow) ? 'Next' : 'Close'}
                                </div>
                            </div>

                        </div>
                    )
                }
            }else{
                if(window.matchMedia('(display-mode: standalone)').matches === false){
                    if(isAndroid){
                        return(
                            <div className="pwa__install-box pwa__install-android animated fadeIn active">
                                To stay in touch with us save the Concierge webpage on your
                                Android: tap Settings
                                <i className="android__setting-icon">
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 384 384">
                                        <circle cx="192" cy="42.667" r="42.667"/>
                                        <circle cx="192" cy="192" r="42.667"/>
                                        <circle cx="192" cy="341.333" r="42.667"/>
                                    </svg>
                                </i> and then Add to the home screen (Install app)
                                <div className="actions__wrap actions__wrap-indent d-flex justify-content-center">
                                    <div onClick={()=>{
                                        this.setState({
                                            boardingSlide: this.state.boardingSlide + 1,
                                            pwa: false
                                        });

                                    }} className="btn__custom d-flex align-items-center justify-content-center">
                                        {(this.state.boarding.length > 0 && this.state.boardingShow) ? 'Next' : 'Close'}
                                    </div>
                                </div>
                            </div>
                        )
                    }
                }
            }
        }
    }

    renderBoardingSlides = () =>{
        if(this.state.boarding.length > 0 && this.state.boardingSlide > 0){
            return(
                <>
                    <div className="pwa__install-box fadeIn animated active">
                        <div className="boarding__slide-title">
                            {this.state.boarding[this.state.boardingSlide - 1].title}
                        </div>
                        <div dangerouslySetInnerHTML={{__html: this.state.boarding[this.state.boardingSlide - 1].description}}/>
                        <div className="actions__wrap actions__wrap-indent d-flex justify-content-center">
                            <div onClick={()=>{
                                if(this.state.boardingSlide < this.state.boarding.length){
                                    this.setState({
                                        boardingSlide: this.state.boardingSlide + 1
                                    });
                                }else{
                                    localStorage.setItem('boarding', 'true');

                                    this.setState({
                                        boardingShow: false
                                    });
                                }

                            }} className="btn__custom d-flex align-items-center justify-content-center">
                                {this.state.boardingSlide < this.state.boarding.length ? 'Next' : 'Close'}
                            </div>
                        </div>
                    </div>
                    {this.state.boardingSlide === 1 ? <div className="footer__col fadeIn animated d-flex align-items-center justify-content-center">
                        <div className="info__icon">
                            <svg height="512" viewBox="0 0 64 64" width="512" xmlns="http://www.w3.org/2000/svg">
                                <path d="m32.387 49.5a3.618 3.618 0 0 0 3.613-3.614v-17.772a3.618 3.618 0 0 0 -3.613-3.614h-4.187a3.614 3.614 0 0 0 0 7.227h.568v14.159a3.619 3.619 0 0 0 3.619 3.614zm-4.187-19.773a1.614 1.614 0 0 1 0-3.227h4.183a1.616 1.616 0 0 1 1.617 1.614v17.772a1.614 1.614 0 1 1 -3.228 0v-16.159z"/>
                                <path d="m32 14.5a4 4 0 1 0 4 4 4 4 0 0 0 -4-4zm0 6a2 2 0 1 1 2-2 2 2 0 0 1 -2 2z"/>
                                <path d="m32 8a24 24 0 0 0 0 48 23.746 23.746 0 0 0 14.941-5.217 1 1 0 1 0 -1.241-1.565 21.757 21.757 0 0 1 -13.7 4.782 22 22 0 1 1 16.678-7.652 1 1 0 1 0 1.515 1.3 24 24 0 0 0 -18.193-39.648z"/>
                            </svg>
                        </div>
                    </div> : <></>}
                    {this.state.boardingSlide === 2 ? <div className="footer__col fadeIn animated d-flex align-items-center justify-content-center">
                        <div className="chat__icon">
                            <svg height="512" viewBox="0 0 64 64" width="512" xmlns="http://www.w3.org/2000/svg">
                                <path d="m59.036 18.473h-14.25v-4.937a3.254 3.254 0 0 0 -3.25-3.25h-35.286a3.254 3.254 0 0 0 -3.25 3.25v23.356a3.254 3.254 0 0 0 3.25 3.25h1.464v7.928a1 1 0 0 0 1.686.73l9.284-8.66h1.816v4.937a3.254 3.254 0 0 0 3.25 3.25h22.856l9.284 8.659a1 1 0 0 0 .681.269 1.012 1.012 0 0 0 .4-.083 1 1 0 0 0 .6-.917v-7.926h1.465a3.254 3.254 0 0 0 3.25-3.25v-23.356a3.254 3.254 0 0 0 -3.25-3.25zm-40.75 19.669a1 1 0 0 0 -.682.269l-7.89 7.359v-6.628a1 1 0 0 0 -1-1h-2.464a1.251 1.251 0 0 1 -1.25-1.25v-23.356a1.252 1.252 0 0 1 1.25-1.25h35.286a1.251 1.251 0 0 1 1.25 1.25v23.356a1.25 1.25 0 0 1 -1.25 1.25zm42 6.937a1.251 1.251 0 0 1 -1.25 1.25h-2.465a1 1 0 0 0 -1 1v6.628l-7.889-7.357a1 1 0 0 0 -.682-.271h-23.25a1.252 1.252 0 0 1 -1.25-1.25v-4.937h19.036a3.254 3.254 0 0 0 3.25-3.25v-16.419h14.25a1.25 1.25 0 0 1 1.25 1.25z"/>
                                <path d="m23.893 23.265a1.887 1.887 0 1 0 1.887 1.887 1.887 1.887 0 0 0 -1.887-1.887z"/>
                                <path d="m34.339 23.265a1.887 1.887 0 1 0 1.887 1.887 1.887 1.887 0 0 0 -1.887-1.887z"/>
                                <path d="m13.446 23.265a1.887 1.887 0 1 0 1.887 1.887 1.886 1.886 0 0 0 -1.887-1.887z"/>
                                <path d="m47.328 27.579h8.958v2h-8.958z"/>
                                <path d="m47.328 32.4h8.958v2h-8.958z"/>
                                <path d="m47.328 37.223h8.958v2h-8.958z"/>
                            </svg>
                        </div>
                    </div> : <></>}
                    {this.state.boardingSlide === 3 ? <div className="footer__col fadeIn animated d-flex align-items-center justify-content-center">
                        <div className="bell__icon">
                            <svg height="512" viewBox="0 0 64 64" width="512" xmlns="http://www.w3.org/2000/svg">
                                <path d="m57 48h-20v-2h21a1 1 0 0 0 1-1 5.017 5.017 0 0 0 -4-4.9v-1.1a19.017 19.017 0 0 0 -19-19v-3a1 1 0 0 0 -1-1h-2v-2h3a1 1 0 0 0 1-1 5 5 0 0 0 -10 0 1 1 0 0 0 1 1h3v2h-2a1 1 0 0 0 -1 1v3a19.017 19.017 0 0 0 -19 19v1.1a5.017 5.017 0 0 0 -4 4.9 1 1 0 0 0 1 1h21v2h-20a5 5 0 0 0 -5 5v1a2.006 2.006 0 0 0 2 2h56a2.006 2.006 0 0 0 2-2v-1a5 5 0 0 0 -5-5zm-27.83-36a3 3 0 0 1 5.66 0zm.83 6h4v2h-4zm-19 21a17.024 17.024 0 0 1 17-17h8a17.024 17.024 0 0 1 17 17v1h-42zm-3.83 5a3.014 3.014 0 0 1 2.83-2h44a3.014 3.014 0 0 1 2.83 2zm27.83 2v2h-6v-2zm25 8h-56v-1a3.009 3.009 0 0 1 3-3h50a3.009 3.009 0 0 1 3 3z"/>
                                <path d="m14.69 17.846-1.068-1.692a26.987 26.987 0 0 0 -10.545 12.46l1.846.772a24.981 24.981 0 0 1 9.767-11.54z"/>
                                <path d="m6.574 30.635 1.862.73a21.279 21.279 0 0 1 8.111-9.96l-1.094-1.674a23.293 23.293 0 0 0 -8.879 10.904z"/>
                                <path d="m59.077 29.386 1.846-.772a26.987 26.987 0 0 0 -10.545-12.46l-1.068 1.692a24.981 24.981 0 0 1 9.767 11.54z"/>
                                <path d="m55.564 31.365 1.862-.73a23.293 23.293 0 0 0 -8.879-10.9l-1.094 1.674a21.279 21.279 0 0 1 8.111 9.956z"/>
                            </svg>
                        </div>
                    </div> : <></>}
                    {this.state.boardingSlide === 4 ? <div className="footer__col fadeIn animated d-flex align-items-center justify-content-center">
                        <div className="requests__icon">
                            <svg height="512" viewBox="0 0 32 32" width="512" xmlns="http://www.w3.org/2000/svg">
                                <path d="m26 10.5a.5.5 0 0 0 -.5.5v12.91a1.59 1.59 0 0 1 -1.59 1.59h-15.82a1.59 1.59 0 0 1 -1.59-1.59v-15.82a1.59 1.59 0 0 1 1.59-1.59h12.91a.5.5 0 0 0 0-1h-12.91a2.59 2.59 0 0 0 -2.59 2.59v15.82a2.59 2.59 0 0 0 2.59 2.59h15.82a2.59 2.59 0 0 0 2.59-2.59v-12.91a.5.5 0 0 0 -.5-.5z"/>
                                <path d="m11.35 14.65a.49.49 0 0 0 -.7.7l3 3a.48.48 0 0 0 .7 0l10-10a.49.49 0 1 0 -.7-.7l-9.65 9.64z"/>
                            </svg>
                        </div>
                    </div> : <></>}
                </>
            )
        }
    }

    renderLoading = () => {
        if(this.state.loading === true){
            return(
                <div className="loading__box d-flex align-items-center justify-content-center">
                    <div className="loader"></div>
                </div>
            )
        }
    }

    renderHelmet = () => {
        if(!isIOS && this.state.token !== '') {
            return(
                <Helmet>
                    <link rel="manifest" id="my-manifest-placeholder" href={'/manifest/' + this.state.token + '.' + this.state.domain + '.' + this.state.part + '.' + this.state.access+ '.' + this.state.room  + '.' + this.state.property + '.json'}/>
                    <title>Concierge</title>
                </Helmet>
            )
        }
    }

    render() {
        return (
            <>
                {
                    this.renderHelmet()
                }
                <div className="page page__content animated fadeIn">
                    <div className="header d-flex align-items-center justify-content-center">
                        <div className="page__title">
                            {this.state.welcome_title}
                        </div>
                    </div>
                    <div className="content content__centered">
                        <div className="container d-flex d-flex align-items-center justify-content-center">
                            <div className="section">
                                <div className="welcome__img">
                                    <img src={this.state.logo} />
                                </div>
                                {
                                    this.state.text_welcome !== '' ? <div className="welcome__title">
                                        {this.state.text_welcome}
                                    </div> : <></>
                                }

                            </div>
                        </div>
                    </div>
                    {
                        this.renderOnBoarding()
                    }
                    {
                        this.renderPwaScreen()
                    }
                    <Notify notify={this.state.notify}/>
                    <Footer chatnot={this.state.chatnot} reqnot={this.state.reqnot} access={this.state.access}/>
                    {
                        this.renderLoading()
                    }
                </div>
            </>
        )

    }
}
