import React, { setGlobal } from 'reactn'
import { Link, Redirect } from 'react-router-dom'
import Footer from './Footer'
import Picker from 'rmc-picker';
import ReactPlayer from 'react-player'
import Slider from "react-slick"
import "../assets/css/animate.css"
import "../assets/css/bootstrap.min.css"
import "../assets/css/style.css"
import {
    isMobile,
    isIOS,
    isMobileSafari,
    isAndroid,
    isChrome
} from "react-device-detect";
import Linking from "./Linking";
let moment = require('moment-timezone');

const API_URL_BEFORE = 'https://';
const API_URL_2 = '.hkeeper.';
const API_URL_3 = '/hk/ga_api';

const monthMap = {
    '1': 'Jan',
    '2': 'Feb',
    '3': 'Mar',
    '4': 'Apr',
    '5': 'May',
    '6': 'Jun',
    '7': 'Jul',
    '8': 'Aug',
    '9': 'Sep',
    '10': 'Oct',
    '11': 'Nov',
    '12': 'Dec',
};

let sl_set = {};

const pickerHours = [{
    value: 0,
    label: '00'
},{
    value: 1,
    label: '01'
},{
    value: 2,
    label: '02'
},{
    value: 3,
    label: '03'
},{
    value: 4,
    label: '04'
},{
    value: 5,
    label: '05'
},{
    value: 6,
    label: '06'
},{
    value: 7,
    label: '07'
},{
    value: 8,
    label: '08'
},{
    value: 9,
    label: '09'
},{
    value: 10,
    label: '10'
},{
    value: 11,
    label: '11'
},{
    value: 12,
    label: '12'
},{
    value: 13,
    label: '13'
},{
    value: 14,
    label: '14'
},{
    value: 15,
    label: '15'
},{
    value: 16,
    label: '16'
},{
    value: 17,
    label: '17'
},{
    value: 18,
    label: '18'
},{
    value: 19,
    label: '19'
},{
    value: 20,
    label: '20'
},{
    value: 21,
    label: '21'
},{
    value: 22,
    label: '22'
},{
    value: 23,
    label: '23'
}];

const pickerMinutes = [{
    value: 0,
    label: '00'
},{
    value: 10,
    label: '10'
},{
    value: 20,
    label: '20'
},{
    value: 30,
    label: '30'
},{
    value: 40,
    label: '40'
},{
    value: 50,
    label: '50'
}];

export default class Amenities extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            act_info: null,
            page_title_default: 'Hotel&#39;s amenities',
            page_title: 'Hotel&#39;s amenities',
            mode: 'category',
            currentAmen: {},
            loaded: false,
            count: 1,
            countList: [],
            options: [],
            optionsGroups: [],
            currentUAID: null,
            data_mode: 'simple',
            comment: '',
            access: 'limited',
            domain: '',
            section_title: '',
            section_subtitle: '',
            amenities: {},
            part: '',
            timeMode: true,
            dateMode: false,
            time: new Date(),
            time2: new Date(),
            isOpen: false,
            isOpen2: false,
            dateChoose: '',
            timeChoose: '',
            days: [],
            date_ch: '',
            hour_ch: '',
            min_ch: '00',
            redirect: false,
            amenitiesEnd: false,
            loader: false,
            submiting: false,
            curHourPick: [],
            curMinPick: [],
        }
        this.handleLoad = this.handleLoad.bind(this);
    }

    componentDidMount() {
        document.getElementById('root').setAttribute('style','height:' + window.innerHeight + 'px');
     /*   document.getElementById('flex-wrap').setAttribute('style','height:' + (window.innerHeight - 109) + 'px');*/

        document.body.classList.add('time-mode');

        this.setGlobal({
            page: 'amenities',
            access: 'full'
        });

        /*if(localStorage.getItem('amenities')){
            this.setState({
                amenities: JSON.parse(localStorage.getItem('amenities'))
            });
        }*/
        if(localStorage.getItem('text_amenities')){
            this.setState({
                section_title: localStorage.getItem('text_amenities')
            });
        }

        if(localStorage.getItem('descr_amenities')){
            this.setState({
                section_subtitle: localStorage.getItem('descr_amenities')
            });
        }

        window.addEventListener('load', this.handleLoad);
        if(localStorage.getItem('access')){
            this.setState({
                access: localStorage.getItem('access')
            })
        }

        if(localStorage.getItem('part')){
            this.setState({
                part: localStorage.getItem('part')
            });
        }

        if(localStorage.getItem('domain')){
            this.setState({
                domain: localStorage.getItem('domain')
            }, ()=>{
                this.checkToken('get');
            });
        }

        let amen = this.state.amenities;
       /* document.getElementById('options-wrap').setAttribute('style','height:' + Object.keys(amen).length * 64 + 'px');*/

        this.generateDays();
    }

    componentWillMount(){
        sl_set = {
            infinite: false,
            dots: true,
            swipeToSlide: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 800
        }
    }

    generateDays = () => {
        let days = [];
        let tmz = localStorage.getItem('timezone');
        if(tmz === null || tmz === undefined || tmz === ''){
            tmz = 'America/New_York';
        }
        let date_tmz = new Date(moment.tz(tmz).format('YYYY/MM/DD HH:mm:ss'));
        let curH = date_tmz.getHours();
        let curM = date_tmz.getMinutes();

        this.generatePickItems();

        this.setState({
            hour_ch: ('0' + curH).slice(-2)
        });

        for(let i = 0; i < 10; i++){
            let date_it_frt = moment(date_tmz).format("MMM Do");
            let date_value = moment(date_tmz).format("YYYY-MM-DD");
            switch(i){
                case 0:
                    this.setState({
                        date_ch: date_value
                    });
                    days.push(<Picker.Item value={date_value} className="my-picker-view-item" key={'day_' + i}>Today</Picker.Item>);
                    break;
                case 1:
                    days.push(<Picker.Item value={date_value} className="my-picker-view-item" key={'day_' + i}>Tomorrow</Picker.Item>);
                    break;
                default:
                    days.push(<Picker.Item value={date_value} className="my-picker-view-item" key={'day_' + i}>{date_it_frt}</Picker.Item>);
                    break;
            }

            date_tmz.setDate(date_tmz.getDate() + 1);

        }
        this.setState({
            days: days
        });
    }

    generatePickItems = (selectedH = -1, selectedD = '') => {
        let tmz = localStorage.getItem('timezone');
        if(tmz === null || tmz === undefined || tmz === ''){
            tmz = 'America/New_York';
        }
        let date_tmz = new Date(moment.tz(tmz).format('YYYY/MM/DD HH:mm:ss'));
        let curD = date_tmz.getDate();
        let curH = date_tmz.getHours();
        let curM = date_tmz.getMinutes();

        let _curHourPick = [];
        let _curMinPick = [];
        let _curHInclude = false;
        let _minCh = false;

        if(selectedD !== '' && (new Date(selectedD).getDate()) > curD){
            this.setState({
                curHourPick: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
                curMinPick: ['00', '10', '20', '30', '40', '50']
            })
        }else{
            if(selectedH == -1 || selectedH === curH) {
                for(let i = 0; i < pickerHours.length; i++){
                    if(pickerHours[i].value > curH){
                        _curHourPick.push(pickerHours[i].label);
                    }else{
                        if(pickerHours[i].value === curH && curM < 50){
                            _curHourPick.push(pickerHours[i].label);
                            _curHInclude = true;
                        }
                    }
                }

                this.setState({
                    curHourPick: _curHourPick
                });
            }

            if(selectedH == -1 || selectedH === curH) {
                for(let i = 0; i < pickerMinutes.length; i++){
                    if(!_curHInclude) {
                        _curMinPick.push(pickerMinutes[i].label);
                        if(!_minCh){
                            _minCh = true;
                            this.setState({
                                min_ch: pickerMinutes[i].label
                            });
                        }
                    }else{
                        if(pickerMinutes[i].value > curM){
                            _curMinPick.push(pickerMinutes[i].label);
                            if(!_minCh){
                                _minCh = true;
                                this.setState({
                                    min_ch: pickerMinutes[i].label
                                });
                            }
                        }
                    }
                }

                this.setState({
                    curMinPick: _curMinPick,
                });
            }else {
                this.setState({
                    curMinPick: ['00', '10', '20', '30', '40', '50'],
                    min_ch: '00'
                });
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('load', this.handleLoad);
    }

    handleLoad() {
        this.setState({
            loaded: true
        });
    }

    checkToken = async(action) => {
        await fetch(API_URL_BEFORE + this.state.domain + API_URL_2 + this.state.part + API_URL_3 + '/token', {
            method: "POST",
            body: JSON.stringify({
                token: localStorage.getItem('ga_auth_key')
            }),
        }).then((response) => response.json()).then((response) => {
            if(response.valid === 0){
                localStorage.setItem('access', 'limited');
                this.setGlobal({
                    access: 'limited'
                });
                this.setState({
                    access: 'limited'
                }, ()=>{
                    window.location.href = '/info';
                });
            }

            if(action === 'send'){
                this.createRequest();
            }else{
                this.getAmenitiesCat();
            }

            if(response.token !== undefined){
                if(response.token === 'refresh'){
                    let room  = localStorage.getItem('room');
                    let domain  = localStorage.getItem('domain');
                    window.location.href = 'https://concierge.hkeeper.global?r=' + room + '&d=' + domain + '&p=global';
                }
            }else{
                if(isMobileSafari && isMobile && isIOS){
                    this.saveToken(localStorage.getItem('ga_auth_key'), this.state.access, this.state.domain, this.state.part, localStorage.getItem('room'));
                }else{
                    this.setState({
                        pwa: true
                    });
                }
            }
        });
    }

    saveToken = async (token, access, domain, part, room) => {
        document.querySelector('#my-manifest-placeholder').setAttribute('href', '/manifest/' + token + '.' + domain + '.' + part + '.' + access+ '.' + room + '.json');
    };

    getAmenitiesCat = async () => {
        this.setState({
            loading: true
        });
        await fetch(API_URL_BEFORE + this.state.domain + API_URL_2 + this.state.part + API_URL_3 + '/amenities', {
            method: "GET"
        }).then((response) => response.json()).then((response) => {
            this.setState({
                amenities: response,
                loading: false
            })
        });
    }

    createRequest = async () => {
        this.setState({
            submiting: true
        });
        let data = {};
        let x = new Date();
        data.comment = this.state.comment;
        data.uuid = localStorage.getItem('ga_auth_key');
        data.uaid = this.state.currentUAID;
        data.widget = this.state.currentAmen.widget;
        switch(this.state.currentAmen.widget){
            case 'image':
                break;
            case 'countable':
                data.options = this.state.count;
                break;
            case 'options':
                if(this.state.currentAmen.refactor === 1){
                    data.options = this.state.optionsGroups;
                }else{
                    data.options = this.state.options;
                }

                break;
            case 'options_countable':
                if(this.state.currentAmen.refactor === 1){
                    data.options = this.state.optionsGroups;
                }else{
                    data.options = this.state.options;
                }
                break;
            case 'time':
                data.options = this.state.date_ch + ' ' + this.state.hour_ch + ':' + this.state.min_ch;
                break;
            case 'date_time':
                data.options = this.state.date_ch + ' ' + this.state.hour_ch + ':' + this.state.min_ch;
                break;
        }

        if(this.state.currentAmen.widget_date === 'date_time' || this.state.currentAmen.widget_date === 'time'){
            data.date = this.state.date_ch + ' ' + this.state.hour_ch + ':' + this.state.min_ch;
        }

        if(this.state.currentAmen.refactor === 1){
            data.refactor = 1;
            if(this.state.currentAmen.widget === 'time' || this.state.currentAmen.widget === 'date_time'){
                data.date = this.state.date_ch + ' ' + this.state.hour_ch + ':' + this.state.min_ch;
            }
        }

        let tmz = localStorage.getItem('timezone');
        if(tmz === null || tmz === undefined || tmz === ''){
            tmz = 'America/New_York';
        }
        let curDate = new Date(moment.tz(tmz).format('YYYY/MM/DD HH:mm:ss'));
        let chDate = new Date(data.date);

        if(curDate > chDate) {
            this.generatePickItems();
            this.linking.errorShow();
            return;
        }

        await fetch(API_URL_BEFORE + this.state.domain + API_URL_2 + this.state.part + API_URL_3 + '/amenities', {
            method: "POST",
            mode: 'cors',
            body: JSON.stringify(data),
            credentials: 'include'
        }).then((response) => response.json()).then((response) => {
            if(response.status === 'ok'){
                this.setState({
                    submiting: false
                });
                this.setState({
                    redirect: true
                });
            }else{
                this.setState({
                    act_info: null,
                    page_title_default: 'Hotel&#39;s amenities',
                    page_title: 'Hotel&#39;s amenities',
                    mode: 'category',
                    currentAmen: {},
                    data_mode: 'simple',
                    comment: '',
                    section_title: '',
                    section_subtitle: '',
                    amenities: {},
                    timeMode: true,
                    dateMode: false,
                    isOpen: false,
                    isOpen2: false,
                    dateChoose: '',
                    timeChoose: '',
                    days: [],
                    date_ch: '',
                    hour_ch: '',
                    min_ch: '30'
                }, ()=>{
                    this.getAmenitiesCat()
                })
            }
            /**/
        });
    }

    countPlus = () => {
        if(this.state.count < 99){
            this.setState({
                count: parseInt(this.state.count) + 1
            })
        }
    }

    countMinus = () => {
        if(this.state.count > 1){
            this.setState({
                count: parseInt(this.state.count) - 1
            })
        }
    }

    renderDays = () => {
        if(this.state.days.length > 0){
            return(
                this.state.days.map((day, ind)=>day)
            )
        }
    }

    renderAmenities = () => {

        let amen = this.state.amenities;
        if(Object.keys(amen).length > 0){
            return(
                Object.keys(amen).map((amenity, i) => (
                    <div key={i} className={'option has-sub-options ' + ((this.state.act_info === i) ? 'active' : '')}>
                        <div onClick={()=>{
                            this.setState({
                                act_info: i
                            });
                            /*document.getElementById('flex-wrap').removeAttribute('style');*/
                            /*document.getElementById('options-wrap').setAttribute('style', 'height:' + (Object.keys(amen[amenity].items).length + 1) * 64 + 'px');*/
                            /*this.scrollToBottom();*/
                        }} className="option__block d-flex align-items-center">
                            <div className="option__block-title">
                                {amenity}
                            </div>
                            <div className="link__next">
                                <i className="las la-angle-right" />
                            </div>
                        </div>
                        <div className="sub__options">
                            <div onClick={()=>{
                                this.setState({
                                    act_info: null
                                }, () => {
                                    document.getElementById('content').scrollTo( 0, 0);
                                });
                                /*document.getElementById('options-wrap').setAttribute('style','height:' + Object.keys(amen).length * 64 + 'px');*/
                            }} className="option__header d-flex align-items-center">
                                <div className="link__back">
                                    <i className="las la-angle-left" />
                                </div>
                                <div className="option__header-title">
                                    {amenity}
                                </div>
                            </div>
                            {
                                Object.keys(amen[amenity].items).map((item, j) => (
                                    <div key={j} onClick={()=>{
                                        this.setState({
                                            currentAmen: amen[amenity].items[item],
                                            page_title: amen[amenity].items[item].title,
                                            currentUAID: item,
                                            comment: ''
                                        }, () => {
                                            document.getElementById('content').scrollTo( 0, 0);
                                        });
                                        if(amen[amenity].items[item].widget === 'date_time'){
                                            document.body.classList.remove('time-mode');
                                        }

                                        if(amen[amenity].items[item].widget === 'time'){
                                            document.body.classList.add('time-mode');
                                        }

                                        let options = [];

                                        let optionsGroups = [];

                                        let option = {};

                                        let optionGroup = {
                                            title: '',
                                            options: []
                                        };

                                        if(amen[amenity].items[item].refactor === 1 && (typeof amen[amenity].items[item].widget_options === 'object' && amen[amenity].items[item].widget_options !== null)){
                                            Object.keys(amen[amenity].items[item].widget_options).map((group, j) => {
                                                optionGroup = {
                                                    title: '',
                                                    options: []
                                                };
                                                optionGroup.options = [];
                                                let title = amen[amenity].items[item].widget_options[group].title;
                                                let groupOptions = amen[amenity].items[item].widget_options[group].options;
                                                if(groupOptions !== undefined){
                                                    optionGroup.title = amen[amenity].items[item].widget_options[group].title;

                                                    Object.keys(groupOptions).map((groupOpt, k) => {
                                                        option = {};
                                                        option.title = groupOptions[groupOpt];
                                                        option.checked = false;
                                                        if(amen[amenity].items[item].widget === 'options_countable'){
                                                            option.count = 0;
                                                        }
                                                        optionGroup.options.push(option);
                                                    });

                                                }else {
                                                    optionGroup.title = '';
                                                    option = {};
                                                    option.title = title;
                                                    option.checked = false;
                                                    if(amen[amenity].items[item].widget === 'options_countable'){
                                                        option.count = 0;
                                                    }
                                                    optionGroup.options = [option];
                                                }

                                                optionsGroups.push(optionGroup);
                                            });

                                            if(amen[amenity].items[item].widget_date === 'date_time'){
                                                document.body.classList.remove('time-mode');
                                            }


                                        }else{
                                            amen[amenity].items[item].widget_options.split('\r\n').map((op, k) => {
                                                option = {};
                                                option.title = op;
                                                option.checked = false;
                                                if(amen[amenity].items[item].widget === 'options_countable'){
                                                    option.count = 0;
                                                }
                                                options.push(option);
                                            });
                                        }

                                        this.setState({
                                            options: options,
                                            optionsGroups: optionsGroups
                                        }, ()=>{
                                            this.setState({
                                                mode: 'detail'
                                            })
                                        });

                                    }} className="option">
                                        <div className="option__block d-flex align-items-center">
                                            <div className="option__block-title">
                                                {amen[amenity].items[item].title}
                                            </div>
                                            {/*<div className="link__next">
                                            <i className="las la-angle-right"></i>
                                        </div>*/}
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                ))
            )
        }

    }

    contentClickHandler = (e) => {
        const targetLink = e.target.closest('a');
        if(!targetLink) return;
        e.preventDefault();
        this.linking.redirectShow(targetLink.href);
    };

    renderAmenityText = () => {
        if(this.state.currentAmen.description !== undefined && this.state.currentAmen.description !== null&& this.state.currentAmen.description !== ''){
            return(
                <div onClick={this.contentClickHandler} className="text__box" dangerouslySetInnerHTML={{__html: this.state.currentAmen.description}}/>
            )
        }
    }

    renderImg = () => {
        if(this.state.currentAmen.image !== undefined && this.state.currentAmen.image !== null && this.state.currentAmen.image !== '' && this.state.currentAmen.image !== '0'){
            return(
                <div className="text__box">
                    <div className="media__slider">
                        <div className="media__item">
                            <img src={this.state.currentAmen.image} />
                        </div>
                    </div>
                </div>
            )
        }
    }

    renderMedia = () => {
        return(
            <div className="media__slider-box">
                <Slider ref={slider1 => (this.slider1 = slider1)} {...sl_set}>
                    {this.renderImg()}
                    {this.renderVideo()}
                </Slider>
            </div>
        )
    }

    renderSubOptions = (optionGroup, j) =>{
        return(
            optionGroup.options.map((option, i)=>(
                <div key={i} className={'form__option d-flex align-items-start ' + ((option.checked === true) ? 'checked' : '')}>
                    <div onClick={()=>{
                        this.checkGroupItem(i, j)
                    }} className="checkbox"></div>
                    <div onClick={()=>{
                        this.checkGroupItem(i, j)
                    }} className="checkbox__title"><span style={{display: ((option.checked !== true || option.count === undefined) ? 'none' : '')}}>{option.count}</span>{option.title}</div>
                    {
                        this.renderGroupCountItem(option, i, j)
                    }
                </div>
            ))
        )
    }

    renderGroupTitle = (title) => {
        if(title !== ''){
            return(
                <div className="group__title">{title}</div>
            )
        }
    }

    renderDatePickList = () => {
        return(
            this.state.curHourPick.map((it, i) => (
                <Picker.Item className="my-picker-view-item" value={it}>{it}</Picker.Item>
            ))
        )
    }

    renderTimePickList = () => {
        return(
            this.state.curMinPick.map((it, i) => (
                <Picker.Item className="my-picker-view-item" value={it}>{it}</Picker.Item>
            ))
        )
    }

    renderFormElements = () => {
        switch(this.state.currentAmen.widget){
            case 'image':
                break;
            case 'countable':
                if(this.state.currentAmen.refactor === 1){
                    return(
                        <div className="form__group">
                            <div className="form__item form__item-countable">
                                <div className={'form__option d-flex align-items-start'}>
                                    <div className="checkbox__title"><span>{this.state.count}</span> {this.state.currentAmen.title}</div>
                                    <div className="form__field">
                                        <div className="count__wrap d-flex align-items-center">
                                            <div className="count__minus" onClick={()=>{this.countMinus()}}>
                                                <i className="las la-minus" />
                                            </div>
                                            <span className="count__value">{this.state.count}</span>
                                            <div className="count__plus" onClick={()=>{this.countPlus()}}>
                                                <i className="las la-plus" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                this.renderTimeWidget(this.state.currentAmen.widget_date === 'time')
                            }
                            {
                                this.renderDateTimeWidget(this.state.currentAmen.widget_date === 'date_time')
                            }
                        </div>
                    )
                }else{
                    return(
                        <div className="form__item form__item-countable">
                            <div className={'form__option d-flex align-items-start'}>
                                <div className="checkbox__title"><span>{this.state.count}</span> {this.state.currentAmen.title}</div>
                                <div className="form__field">
                                    <div className="count__wrap d-flex">
                                        <div className="count__minus" onClick={()=>{this.countMinus()}}>
                                            <i className="las la-minus" />
                                        </div>
                                        <span className="count__value">{this.state.count}</span>
                                        <div className="count__plus" onClick={()=>{this.countPlus()}}>
                                            <i className="las la-plus" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }

                break;
            case 'options':
                if(this.state.currentAmen.refactor === 1){
                    return(
                        <div className="form__group">
                            <div className="form__item">
                                {
                                    this.state.optionsGroups.map((optionGroup, j)=>(
                                        <div className={'optionsGroup__item ' + ((optionGroup.title !== '' ? 'group-item' : ''))}>
                                            {
                                                this.renderGroupTitle(optionGroup.title)
                                            }
                                            {
                                                this.renderSubOptions(optionGroup, j)
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                            {
                                this.renderTimeWidget(this.state.currentAmen.widget_date === 'time')
                            }
                            {
                                this.renderDateTimeWidget(this.state.currentAmen.widget_date === 'date_time')
                            }
                        </div>
                    )

                }else{
                    return(
                        <div className="form__item">
                            {
                                this.state.options.map((option, i)=>(
                                    <div key={i} className={'form__option d-flex align-items-start ' + ((option.checked === true) ? 'checked' : '')}>
                                        <div onClick={()=>{
                                            this.checkItem(i)
                                        }} className="checkbox"></div>
                                        <div onClick={()=>{
                                            this.checkItem(i)
                                        }} className="checkbox__title">{option.title}</div>
                                    </div>
                                ))
                            }
                        </div>
                    )

                }

                break;
            case 'options_countable':
                if(this.state.currentAmen.refactor === 1){
                    return(
                        <div className="form__group">
                            <div className="form__item form__item-countable">
                                {
                                    this.state.optionsGroups.map((optionGroup, j)=>(
                                        <div className={'optionsGroup__item ' + ((optionGroup.title !== '' ? 'group-item' : ''))}>
                                            {
                                                this.renderGroupTitle(optionGroup.title)
                                            }
                                            {
                                                this.renderSubOptions(optionGroup, j)
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                            {
                                this.renderTimeWidget(this.state.currentAmen.widget_date === 'time')
                            }
                            {
                                this.renderDateTimeWidget(this.state.currentAmen.widget_date === 'date_time')
                            }
                        </div>
                    )
                }else{
                    return(
                        <div className="form__item form__item-countable">
                            {
                                this.state.options.map((option, i)=>(
                                    <div key={i} className={'form__option d-flex align-items-start ' + ((option.checked === true) ? 'checked' : '')}>
                                        <div onClick={()=>{
                                            this.checkItem(i)
                                        }} className="checkbox"></div>
                                        <div onClick={()=>{
                                            this.checkItem(i)
                                        }} className="checkbox__title"><span style={{display: ((option.checked !== true) ? 'none' : '')}}>{option.count}</span>{option.title}</div>
                                        {
                                            this.renderCountItem(option, i)
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    )
                }

                break;
            case 'time':
                return(
                    <div className="pickers__wrap">
                        <div className="pickers__wrap-box">
                            <div className="row">
                                <div className="col-6 date-col">
                                    <div className="date__wrap">
                                        <Picker onValueChange={(value)=>{
                                            this.setState({
                                                date_ch: value
                                            })
                                            this.generatePickItems(-1, value);
                                        }} indicatorClassName="my-picker-indicator">
                                            {this.renderDays()}
                                        </Picker>
                                    </div>
                                </div>
                                <div className="col-6 time-col">
                                    <div className="time__wrap-over">
                                        <div className="time__wrap">
                                            <div className="row">
                                                <div className="col-6">
                                                    <Picker selectedValue={this.state.hour_ch} onValueChange={(value)=>{
                                                        this.setState({
                                                            hour_ch: value
                                                        });
                                                        this.generatePickItems(parseInt(value), this.state.date_ch);
                                                    }}  indicatorClassName="my-picker-indicator">
                                                        {this.renderDatePickList()}
                                                    </Picker>
                                                </div>
                                                <div className="col-6">
                                                    <Picker selectedValue={this.state.min_ch} onValueChange={(value)=>{
                                                        this.setState({
                                                            min_ch: value
                                                        })
                                                    }} indicatorClassName="my-picker-indicator">
                                                        {this.renderTimePickList()}
                                                    </Picker>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                break;
            case 'date_time':
                return(
                    <div className="pickers__wrap">
                        <div className="pickers__wrap-box">
                            <div className="row">
                                <div className="col-6 date-col">
                                    <div className="date__wrap">
                                        <Picker onValueChange={(value)=>{
                                            this.setState({
                                                date_ch: value
                                            })
                                            this.generatePickItems(-1, value);
                                        }} indicatorClassName="my-picker-indicator">
                                            {this.renderDays()}
                                        </Picker>
                                    </div>
                                </div>
                                <div className="col-6 time-col">
                                    <div className="time__wrap-over">
                                        <div className="time__wrap">
                                            <div className="row">
                                                <div className="col-6">
                                                    <Picker selectedValue={this.state.hour_ch} onValueChange={(value)=>{
                                                        this.setState({
                                                            hour_ch: value
                                                        })
                                                        this.generatePickItems(parseInt(value), this.state.date_ch);
                                                    }}  indicatorClassName="my-picker-indicator">
                                                        {this.renderDatePickList()}
                                                    </Picker>
                                                </div>
                                                <div className="col-6">
                                                    <Picker selectedValue={this.state.min_ch} onValueChange={(value)=>{
                                                        this.setState({
                                                            min_ch: value
                                                        })
                                                    }} indicatorClassName="my-picker-indicator">
                                                        {this.renderTimePickList()}
                                                    </Picker>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                break;
            default:
                return(
                    <div className="form__group">
                        {
                            this.renderTimeWidget(this.state.currentAmen.widget_date === 'time')
                        }
                        {
                            this.renderDateTimeWidget(this.state.currentAmen.widget_date === 'date_time')
                        }
                    </div>
                )
                break;
        }
    }

    renderTimeWidget = (show) => {
        if(show){
            return(
                <div className="pickers__wrap">
                    <div className="pickers__wrap-box">
                        <div className="row">
                            <div className="col-6 date-col">
                                <div className="date__wrap">
                                    <Picker onValueChange={(value)=>{
                                        this.setState({
                                            date_ch: value
                                        })
                                        this.generatePickItems(-1, value);
                                    }} indicatorClassName="my-picker-indicator">
                                        {this.renderDays()}
                                    </Picker>
                                </div>
                            </div>
                            <div className="col-6 time-col">
                                <div className="time__wrap-over">
                                    <div className="time__wrap">
                                        <div className="row">
                                            <div className="col-6">
                                                <Picker selectedValue={this.state.hour_ch} onValueChange={(value)=>{
                                                    this.setState({
                                                        hour_ch: value
                                                    })
                                                    this.generatePickItems(parseInt(value), this.state.date_ch);
                                                }}  indicatorClassName="my-picker-indicator">
                                                    {this.renderDatePickList()}
                                                </Picker>
                                            </div>
                                            <div className="col-6">
                                                <Picker selectedValue={this.state.min_ch} onValueChange={(value)=>{
                                                    this.setState({
                                                        min_ch: value
                                                    })
                                                }} indicatorClassName="my-picker-indicator">
                                                    {this.renderTimePickList()}
                                                </Picker>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    renderDateTimeWidget = (show) => {

        if(show){
            return(
                <div className="pickers__wrap">
                    <div className="pickers__wrap-box">
                        <div className="row">
                            <div className="col-6 date-col">
                                <div className="date__wrap">
                                    <Picker onValueChange={(value)=>{
                                        this.setState({
                                            date_ch: value
                                        });
                                        this.generatePickItems(-1, value);
                                    }} indicatorClassName="my-picker-indicator">
                                        {this.renderDays()}
                                    </Picker>
                                </div>
                            </div>
                            <div className="col-6 time-col">
                                <div className="time__wrap-over">
                                    <div className="time__wrap">
                                        <div className="row">
                                            <div className="col-6">
                                                <Picker selectedValue={this.state.hour_ch} onValueChange={(value)=>{
                                                    this.setState({
                                                        hour_ch: value
                                                    })
                                                    this.generatePickItems(parseInt(value), this.state.date_ch);
                                                }}  indicatorClassName="my-picker-indicator">
                                                    {this.renderDatePickList()}
                                                </Picker>
                                            </div>
                                            <div className="col-6">
                                                <Picker selectedValue={this.state.min_ch} onValueChange={(value)=>{
                                                    this.setState({
                                                        min_ch: value
                                                    })
                                                }} indicatorClassName="my-picker-indicator">
                                                    {this.renderTimePickList()}
                                                </Picker>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

    }

    plusItem = (i) => {
        let opts = this.state.options;
        opts[i].count = opts[i].count + 1;
        opts[i].checked = true;
        this.setState({
            options: opts
        })
    }

    minusItem = (i) => {
        let opts = this.state.options;
        if(opts[i].count > 0){
            opts[i].count = opts[i].count - 1;
            if(opts[i].count === 0){
                opts[i].checked = false;
            }
        }else{
            opts[i].checked = false;
        }

        this.setState({
            options: opts
        });
    }

    plusGroupItem = (i, grIndex) => {
        let opts = this.state.optionsGroups;
        opts[grIndex].options[i].count = opts[grIndex].options[i].count + 1;
        opts[grIndex].options[i].checked = true;
        this.setState({
            optionsGroups: opts
        });
    }

    minusGroupItem = (i, grIndex) => {
        let opts = this.state.optionsGroups;
        if(opts[grIndex].options[i].count > 0){
            opts[grIndex].options[i].count = opts[grIndex].options[i].count - 1;
            if(opts[grIndex].options[i].count === 0){
                opts[grIndex].options[i].checked = false;
            }
        }else{
            opts[grIndex].options[i].checked = false;
        }

        this.setState({
            optionsGroups: opts
        });
    }

    renderCountItem = (option, i) => {
        if(option.count !== undefined){
            return(
                <div className="form__field">
                    <div className={'count__wrap d-flex align-items-center'}>
                        <div className="count__minus" onClick={()=>{this.minusItem(i)}}>
                            <i className="las la-minus" />
                        </div>
                        <span className="count__value">{option.count}</span>
                        <div className="count__plus" onClick={()=>{this.plusItem(i)}}>
                            <i className="las la-plus" />
                        </div>
                    </div>
                </div>
            )
        }
    }

    renderGroupCountItem = (option, i, j) => {
        if(option.count !== undefined){
            return(
                <div className="form__field">
                    <div className={'count__wrap d-flex align-items-center'}>
                        <div className="count__minus" onClick={()=>{this.minusGroupItem(i, j)}}>
                            <i className="las la-minus" />
                        </div>
                        <span className="count__value">{option.count}</span>
                        <div className="count__plus" onClick={()=>{this.plusGroupItem(i, j)}}>
                            <i className="las la-plus" />
                        </div>
                    </div>
                </div>
            )
        }
    }

    checkItem = (index) => {
        let opts = this.state.options;
        opts[index].checked = !opts[index].checked;

        if(opts[index].checked === true){
            if(opts[index].count !== undefined){
                opts[index].count = 1;
            }
        }else{
            if(opts[index].count !== undefined){
                opts[index].count = 0;
            }
        }

        this.setState({
            options: opts
        })
    }

    checkGroupItem = (index, grIndex) => {
        let opts = this.state.optionsGroups;
        opts[grIndex].options[index].checked = !opts[grIndex].options[index].checked;

        if(opts[grIndex].options[index].checked === true){
            if(opts[grIndex].options[index].count !== undefined){
                opts[grIndex].options[index].count = 1;
            }
        }else{
            if(opts[grIndex].options[index].count !== undefined){
                opts[grIndex].options[index].count = 0;
            }
        }

        this.setState({
            optionsGroups: opts
        })
    }

    scrollToBottom = () => {
        setTimeout(()=>{
            this.optionsEnd.scrollIntoView({ behavior: "smooth" });
        }, 600);

    }

    renderSectionDescr = () => {
        if(this.state.section_subtitle !== ''){
            return(
                <div className="section__subtitle">
                    {this.state.section_subtitle}
                </div>
            )
        }
    }

    renderVideo = () => {
        if(this.state.currentAmen.video !== undefined && this.state.currentAmen.video !== null && this.state.currentAmen.video !== ''){
            return(
                <div className="text__box">
                    <div className="video__item">
                        <ReactPlayer url={this.state.currentAmen.video} loop={true} controls={false} playsinline={true} config={{
                            youtube: {
                                playerVars: { showinfo: 0, autoplay: 0, controls: 0, rel: 0, loop: 1}
                            }
                        }} />
                    </div>
                </div>
            )
        }
    }

    handleScroll = (event) =>{
        const target = event.target;
        if(target.scrollHeight - target.scrollTop === target.clientHeight){
            this.setState({
                amenitiesEnd: true
            })
        }else{
            this.setState({
                amenitiesEnd: false
            })
        }
    }

    renderPageContent = () => {
        switch(this.state.mode){
            case 'category':
                return(
                    <div id="content" className="content" onScroll={this.handleScroll}>
                        <div className="content__scroll">
                            <div className="container">
                                <div id="flex-wrap" className="section section__down d-flex">
                                    <div className="section__header d-flex align-items-center">
                                        <div className="section__decor-icon">
                                            <svg height="512" viewBox="0 0 64 64" width="512" xmlns="http://www.w3.org/2000/svg">
                                                <path d="m57 48h-20v-2h21a1 1 0 0 0 1-1 5.017 5.017 0 0 0 -4-4.9v-1.1a19.017 19.017 0 0 0 -19-19v-3a1 1 0 0 0 -1-1h-2v-2h3a1 1 0 0 0 1-1 5 5 0 0 0 -10 0 1 1 0 0 0 1 1h3v2h-2a1 1 0 0 0 -1 1v3a19.017 19.017 0 0 0 -19 19v1.1a5.017 5.017 0 0 0 -4 4.9 1 1 0 0 0 1 1h21v2h-20a5 5 0 0 0 -5 5v1a2.006 2.006 0 0 0 2 2h56a2.006 2.006 0 0 0 2-2v-1a5 5 0 0 0 -5-5zm-27.83-36a3 3 0 0 1 5.66 0zm.83 6h4v2h-4zm-19 21a17.024 17.024 0 0 1 17-17h8a17.024 17.024 0 0 1 17 17v1h-42zm-3.83 5a3.014 3.014 0 0 1 2.83-2h44a3.014 3.014 0 0 1 2.83 2zm27.83 2v2h-6v-2zm25 8h-56v-1a3.009 3.009 0 0 1 3-3h50a3.009 3.009 0 0 1 3 3z"/>
                                                <path d="m14.69 17.846-1.068-1.692a26.987 26.987 0 0 0 -10.545 12.46l1.846.772a24.981 24.981 0 0 1 9.767-11.54z"/>
                                                <path d="m6.574 30.635 1.862.73a21.279 21.279 0 0 1 8.111-9.96l-1.094-1.674a23.293 23.293 0 0 0 -8.879 10.904z"/>
                                                <path d="m59.077 29.386 1.846-.772a26.987 26.987 0 0 0 -10.545-12.46l-1.068 1.692a24.981 24.981 0 0 1 9.767 11.54z"/>
                                                <path d="m55.564 31.365 1.862-.73a23.293 23.293 0 0 0 -8.879-10.9l-1.094 1.674a21.279 21.279 0 0 1 8.111 9.956z"/>
                                            </svg>
                                        </div>
                                        <div className="section__header-content">
                                            <div className="section__title">
                                                {this.state.section_title}
                                            </div>
                                            {
                                                this.renderSectionDescr()
                                            }
                                        </div>
                                    </div>
                                    <div id="options-wrap" className={'options__wrap d-flex align-items-end ' + ((this.state.act_info !== null) ? 'active' : '')}>
                                        <div className="options__wrap-block">
                                            <div className="options__label">
                                                Please, choose one of the amenities
                                            </div>
                                            <div className={'options__list ' + ((this.state.act_info !== null) ? 'active' : '')}>
                                                {
                                                    this.renderAmenities()
                                                }
                                            </div>
                                            <div ref={(el) => { this.optionsEnd = el; }} className="options__end"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
                break;

            case 'detail':
                return(
                    <div id="content" className="content">
                        <div className="content__scroll">
                            <div className="container">
                                <div className="section section__down section__create section__top">
                                    <div className="section__header">
                                        {this.renderMedia()}
                                        {this.renderAmenityText()}
                                    </div>
                                    <div className="form__custom">
                                        {
                                            this.renderFormElements()
                                        }
                                        <div className="form__item">
                                            <div className="form__field">
                                                <textarea onChange={(comment)=>{
                                                    this.setState({comment: comment.target.value});
                                                }}  placeholder="Comment" defaultValue={""} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'form__item form__item-actions ' + ((this.state.submiting) ? 'disabled' : '')}>
                                        <div onClick={()=>{
                                            this.checkToken('send');
                                        }} className="btn__custom d-flex align-items-center justify-content-center show__code">
                                            Create a request
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
                break;
        }
    }

    renderBackLink = () => {
        if(this.state.mode === 'detail'){
            return(
                <div onClick={()=>{
                    this.setState({
                        mode: 'category',
                        page_title: this.state.page_title_default,
                        currentAmen: {}
                    }, ()=>{
                        document.getElementById('root').setAttribute('style','height:' + window.innerHeight + 'px');
                     /*   document.getElementById('flex-wrap').setAttribute('style','height:' + (window.innerHeight - 109) + 'px');*/
                    });

                }} className="link__back">
                    <i className="las la-angle-left"></i>
                </div>
            )
        }
    }

    renderLoading = () => {
        if(this.state.loading === true){
            return(
                <div className="loading__box d-flex align-items-center justify-content-center">
                    <div className="loader"></div>
                </div>
            )
        }
    }

    render() {
        if(this.state.redirect === true){
            return(
                <Redirect to="/requests"/>
            )
        }else{
            return (
                <div className={'page page__content page__amenities animated fadeIn ' + ((this.state.loaded === true) ? 'loaded ' : '') + ((this.state.amenitiesEnd === true) ? 'scroll-bottom' : '')}>
                    <div className="header d-flex align-items-center justify-content-center">
                        {
                            this.renderBackLink()
                        }
                        <div className="page__title" dangerouslySetInnerHTML={{__html: this.state.page_title}}/>
                    </div>
                    {
                        this.renderPageContent()
                    }
                    <Footer ref={instance => { this.footer = instance; }} chatnot={this.state.chatnot} reqnot={this.state.reqnot} access={this.state.access} page={'amenities'}/>
                    {
                        this.renderLoading()
                    }
                    <Linking ref={instance => { this.linking = instance; }} />
                </div>
            )
        }

    }
}
