import React from 'react'
import { render } from 'react-dom'
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { BrowserRouter } from 'react-router-dom'
import App from './components/App';

Sentry.init({
    dsn: "https://9eeadadcd71c42158d9ee080705b8d80@o212934.ingest.sentry.io/5566849",
    autoSessionTracking: true,
    integrations: [
        new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
});

render((
  <BrowserRouter>
    <App />
  </BrowserRouter>
), document.getElementById('root'));
