import React, {setGlobal} from 'reactn'
import {Link, Redirect} from 'react-router-dom'
import Footer from './Footer'
import Linking from './Linking'
import ReactPlayer from 'react-player'
import Slider from "react-slick"
import "../assets/css/animate.css"
import "../assets/fonts/flaticon.css"
import "../assets/css/bootstrap.min.css"
import "../assets/css/style.css"
import {
    isMobile,
    isIOS,
    isMobileSafari,
    isAndroid,
    isChrome
} from "react-device-detect";

const API_URL_BEFORE = 'https://';
const API_URL_2 = '.hkeeper.';
const API_URL_3 = '/hk/ga_api';

let sl_set = {};

export default class Info extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            act_info: null,
            page_title_default: 'General information',
            page_title: 'General information',
            mode: 'category',
            access: 'limited',
            currentInfo: {},
            informers: {},
            domain: '',
            info_img: null,
            notify: '',
            chatnot: false,
            reqnot: false,
            loading: false
        }
    }


    contentClickHandler = (e) => {
        const targetLink = e.target.closest('a');
        if(!targetLink) return;
        e.preventDefault();
        this.linking.redirectShow(targetLink.href);
    };

    componentDidMount() {
        this.setGlobal({
            page: 'info'
        });

        document.getElementById('root').setAttribute('style', 'height:' + window.innerHeight + 'px');
        document.getElementById('content').setAttribute('style', 'height:' + window.innerHeight - 55 + 'px');

        if (localStorage.getItem('access')) {
            this.setState({
                access: localStorage.getItem('access')
            });
            this.setGlobal({
                access: localStorage.getItem('access')
            });
        }

        if (localStorage.getItem('part')) {
            this.setState({
                part: localStorage.getItem('part')
            });
        }

        if (localStorage.getItem('domain')) {
            this.setState({
                domain: localStorage.getItem('domain')
            }, () => {
                this.checkToken();
            })
        }

        if (localStorage.getItem('info_img')) {
            this.setState({
                info_img: localStorage.getItem('info_img')
            });
        }

    }

    saveToken = async (token, access, domain, part, room) => {
        document.querySelector('#my-manifest-placeholder').setAttribute('href', '/manifest/' + token + '.' + domain + '.' + part + '.' + access + '.' + room + '.json');
    };

    componentWillMount() {
        sl_set = {
            infinite: false,
            dots: true,
            swipeToSlide: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 800
        }
    }

    checkToken = async () => {
        await fetch(API_URL_BEFORE + this.state.domain + API_URL_2 + this.state.part + API_URL_3 + '/token', {
            method: "POST",
            body: JSON.stringify({
                token: localStorage.getItem('ga_auth_key')
            }),
        }).then((response) => response.json()).then((response) => {
            if (response.valid === 0) {
                localStorage.setItem('access', 'limited');
                this.setGlobal({
                    access: 'limited'
                });
                this.setState({
                    access: 'limited'
                });
            }

            this.getInformersCat();

            if (response.token !== undefined) {
                if (response.token === 'refresh') {
                    let room = localStorage.getItem('room');
                    let domain = localStorage.getItem('domain');
                    window.location.href = 'https://concierge.hkeeper.global?r=' + room + '&d=' + domain + '&p=global';
                }
            }/* else {
                if (isMobileSafari && isMobile && isIOS) {
                    this.saveToken(localStorage.getItem('ga_auth_key'), this.state.access, this.state.domain, this.state.part, localStorage.getItem('room'));
                }
            }*/
        });
    }

    getInformersCat = async () => {
        this.setState({
            loading: true
        });
        await fetch(API_URL_BEFORE + this.state.domain + API_URL_2 + this.state.part + API_URL_3 + '/informers', {
            method: "GET"
        }).then((response) => response.json()).then((response) => {
            this.setState({
                informers: response,
                loading: false
            });
        });
    }

    renderInfoIcon = (icon) => {
        if (icon !== '') {
            return (
                <i className={'fi ' + icon}/>
            )
        } else {
            return (
                <i className="las la-hotel"/>
            )
        }
    }

    renderInformers = () => {
        let inform = this.state.informers;

        return (
            Object.keys(inform).map((informer, i) => (
                <div key={i}
                     className={'option has-sub-options ' + ((this.state.act_info === i) ? 'active' : '')}>
                    <div onClick={() => {
                        this.setState({
                            act_info: i
                        })
                    }} className="option__block d-flex align-items-center">
                        <div className="option__block-title">
                            {informer}
                        </div>
                        <div className="link__next">
                            <i className="las la-angle-right"/>
                        </div>
                    </div>
                    <div className="sub__options">
                        <div onClick={() => {
                            this.setState({
                                act_info: null
                            }, () => {
                                document.getElementById('content').scrollTo(0, 0);
                            })
                        }} className="option__header d-flex align-items-center">
                            <div className="link__back">
                                <i className="las la-angle-left"/>
                            </div>
                            <div className="option__header-title">
                                {informer}
                            </div>
                        </div>
                        {
                            Object.keys(inform[informer].items).map((item, j) => (
                                <div key={j} onClick={() => {
                                    this.setState({
                                        currentInfo: inform[informer].items[item],
                                        page_title: inform[informer].items[item].title,
                                        mode: 'detail'
                                    }, () => {
                                        document.getElementById('content').scrollTo(0, 0);
                                    })
                                }} className="option">
                                    <div className="option__block d-flex align-items-center">
                                        <div className="option__block-title">
                                            {inform[informer].items[item].title}
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            ))
        )
    }

    renderInfoText = () => {
        if (this.state.currentInfo.description !== undefined && this.state.currentInfo.description !== null && this.state.currentInfo.description !== '') {
            return (
                <div onClick={this.contentClickHandler} className="text__box"
                     dangerouslySetInnerHTML={{__html: this.state.currentInfo.description}}/>
            )
        }
    }

    renderMedia = () => {
        return (
            <div className="media__slider-box">
                <Slider ref={slider1 => (this.slider1 = slider1)} {...sl_set}>
                    {this.renderImg()}
                    {this.renderVideo()}
                </Slider>
            </div>
        )
    }

    renderImg = () => {
        if (this.state.currentInfo.image !== undefined && this.state.currentInfo.image !== null && this.state.currentInfo.image !== '') {
            return (
                <div className="text__box">
                    <div className="media__slider">
                        <div className="media__item">
                            <img src={this.state.currentInfo.image}/>
                        </div>
                    </div>
                </div>
            )
        }
    }

    renderVideo = () => {
        if (this.state.currentInfo.youtube !== undefined && this.state.currentInfo.youtube !== null && this.state.currentInfo.youtube !== '') {
            return (
                <div className="text__box">
                    <div className="video__item">
                        <ReactPlayer url={this.state.currentInfo.youtube} playing={false} loop={true}
                                     controls={false} playsinline={true} config={{
                            youtube: {
                                playerVars: {showinfo: 0, autoplay: 0, controls: 0, rel: 0, loop: 1}
                            }
                        }}/>
                    </div>
                </div>
            )
        }
    }

    renderInfoImg = () => {
        if (this.state.info_img !== null) {
            return (
                <img src={this.state.info_img}/>
            )
        }
    }

    renderPageContent = () => {
        switch (this.state.mode) {
            case 'category':
                return (
                    <div className="section sc__info-cat fadeInUpDes animated">
                        <div className="static__img">
                            {
                                this.renderInfoImg()
                            }
                        </div>

                        <div
                            className={'options__list ' + ((this.state.act_info !== null) ? 'active' : '')}>
                            {
                                this.renderInformers()
                            }
                        </div>
                    </div>
                );
                break;

            case 'detail':
                return (
                    <div className="section section__top fadeInUpDes animated">
                        <div className="text">
                            {
                                this.renderMedia()
                            }
                            {
                                this.renderInfoText()
                            }

                        </div>
                    </div>
                );
                break;
        }
    }

    renderBackLink = () => {
        if (this.state.mode === 'detail') {
            return (
                <div onClick={() => {
                    this.setState({
                        mode: 'category',
                        page_title: this.state.page_title_default,
                        currentInfo: {}
                    })
                }} className="link__back">
                    <i className="las la-angle-left"/>
                </div>
            )
        }
    }

    renderLoading = () => {
        if (this.state.loading === true) {
            return (
                <div className="loading__box d-flex align-items-center justify-content-center">
                    <div className="loader"></div>
                </div>
            )
        }
    }

    render() {
        return (
            <div className="page page__content animated fadeIn">
                <div className="header d-flex align-items-center justify-content-center">
                    {
                        this.renderBackLink()
                    }
                    <div className="page__title">
                        {this.state.page_title}
                    </div>
                </div>
                <div className="content" id="content">
                    <div className="container">
                        {/*Token: {localStorage.getItem('ga_auth_key')} <br/>
                        Domain: {localStorage.getItem('domain')} <br/>
                        Room: {localStorage.getItem('room')} <br/>
                        Access: {localStorage.getItem('access')}*/}
                        {
                            this.renderPageContent()
                        }
                    </div>
                </div>

                <Footer chatnot={this.state.chatnot} reqnot={this.state.reqnot}
                        access={this.state.access} page={'info'}/>

                {
                    this.renderLoading()
                }

                <Linking ref={instance => { this.linking = instance; }} />
            </div>
        )

    }
}
