import React, { setGlobal } from 'reactn'
import { Link } from 'react-router-dom'
import "../assets/css/bootstrap.min.css"

export default class Notify extends React.Component {
    constructor(props){
        super(props);
    }

    render() {
        return (
            <div className={'notify__box ' + ((this.props.notify !== '') ? 'active' : '')}>
                <div className={'notify__box-block d-flex align-items-center justify-content-center'}>
                    <div className="notify__box-title">
                        {this.props.notify}
                    </div>
                </div>
            </div>
        )
    }
}