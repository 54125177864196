import React, { setGlobal } from 'reactn'
import { Link } from 'react-router-dom'
import "../assets/css/bootstrap.min.css"

export default class Linking extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            modal_redirect: false,
            modal_error: false,
            redirect_link: ''
        }
    }

    redirectShow = (link) => {
        this.setState({
            modal_redirect: true,
            redirect_link: link
        });
    }

    errorShow = () => {
        this.setState({
            modal_error: true
        });
    }

    render() {
        return (
            <>
                <div id="modal-redirect" className={'modal ' + ((this.state.modal_redirect) ? 'active' : '')}>
                    <div className="modal__box">
                        <div onClick={()=>{
                            this.setState({
                                modal_redirect: false
                            });
                        }} className="close__modal">
                            <i className="las la-times" />
                        </div>
                        <div className="modal__box-title page__title page__title--rows">
                            Do not forget to bookmark our Concierge page. Leave the site?
                        </div>
                        <div className="form__custom redirect-actions">
                            <div className="row row__custom">
                                <div className="col-6 col__custom">
                                    <div onClick={()=>{
                                        this.setState({
                                            modal_redirect: false
                                        });
                                        window.open(this.state.redirect_link, '_blank').focus();
                                    }} className="btn__custom d-flex align-items-center justify-content-center">
                                        Yes
                                    </div>
                                </div>
                                <div className="col-6 col__custom">
                                    <div onClick={()=>{
                                        this.setState({
                                            modal_redirect: false
                                        });
                                    }} className="btn__custom btn__custom-cancel d-flex align-items-center justify-content-center">
                                        No
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal-error" className={'modal ' + ((this.state.modal_error) ? 'active' : '')}>
                    <div className="modal__box">
                        <div onClick={()=>{
                            this.setState({
                                modal_error: false
                            });
                        }} className="close__modal">
                            <i className="las la-times" />
                        </div>
                        <div className="modal__box-title page__title page__title--rows">
                            Your request time is incorrect. Please, choose another one.
                        </div>
                        <div className="form__custom redirect-actions">
                            <div className="row row__custom justify-content-center">
                                <div className="col-6 col__custom">
                                    <div onClick={()=>{
                                        this.setState({
                                            modal_error: false
                                        });
                                    }} className="btn__custom d-flex align-items-center justify-content-center">
                                        Ok
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
