import React, { setGlobal } from 'reactn'
import { Switch, Route, Router } from 'react-router-dom'
import Home from './Home'
import Login from './Login'
import Info from './Info'
import Amenities from './Amenities'
import Requests from './Requests'
import Chat from './Chat'
import NotFound from './Error'
import "../assets/css/slick.min.css"


export default class Main extends React.Component {
    render(){
        return(
            <div className="full-height">
                <Switch>
                    <Route exact path='/' component={Home}/>
                    <Route exact path='/login' component={Login}/>
                    <Route exact path='/info' component={Info}/>
                    <Route exact path='/amenities' component={Amenities}/>
                    <Route exact path='/requests' component={Requests}/>
                    <Route exact path='/chat' component={Chat}/>
                    <Route component={NotFound} />
                </Switch>
            </div>
        )
    }
}
