import React, { setGlobal } from 'reactn'
import Main from './Main'

export default class App extends React.Component {

    render(){
        return(
            <Main />
        )
    }
}